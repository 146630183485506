<template>
  <div class="main">
    <div class="container-ul">
       <h1>vitamin </h1>
    </div>
  </div>
</template>
<script>
export default {
  components: {
  },
  data () {
    return {
    }
  },
  mounted() {

  },
  computed: {

  },
  watch: {

  },
  methods: {
  }
}
</script>