<template>
  <div class="goal-step abs-full-block profile">
    <div class="sign-in__flex">
      <form @submit.prevent="updateData" class="w-100">
        <p class="goal-step__title">Профиль</p>


        <label class="base-label">Имя </label>
        <input class="base-input" type="text"  placeholder="" v-model="user.name" required>

        <label class="base-label">Фамилия</label>
        <input class="base-input" type="text"  placeholder="" v-model="user.surname" required>

        <label class="base-label">Nickname</label>
        <input class="base-input" type="text"  placeholder="" v-model="user.nickname" required>

        <div class="w-100">
          <button   type="submit" class="base-btn base-btn--violet mt-4">Сохранить</button>
          <router-link :to="{name: 'profile'}" class="forgot-password mb-3">Отменить</router-link>
        </div>



      </form>

    </div>
  </div>
</template>
<script>
import Profile from "@/api/Profile";

export default {
  data() {
    return {
      user: {
        name: null,
        surname: null,
        nickname: null
      }
    }
  },
  methods: {
    updateData() {
      let params = {
        name: this.user.name,
        surname: this.user.surname,
        nickname: this.user.nickname
      }
      Profile.editInfo(data => this.data = data, errors => this.errors = errors, params)
          .then(() => {
            this.$store.dispatch('SHOW_ALERT', [this.data.message])
            setTimeout(() => {
              this.$router.push({ name: 'profile' })
            }, 0)
          })
    }
  }
}
</script>