<template>
  <div class="main single plan-desc">
    <div class="single__cover">
      <img src="../../../assets/images/bg-plan.jpg" alt="" class="single__cover__img">
      <div class="abs-full-block transparent-blue single__cover__blog">
      </div>
    </div>
    <div class="single__body">
      <div class="container-ul">
        <div class="grey_line mb-3"></div>

        <p class="single__title">Диета от Арнольда</p>

        <p class="single__step-title">ФАЗА-1</p>
        <p class="single__content">Значимость этих проблем настолько очевидна, что новая модель организационной деятельности позволяет выполнять важные задания по разработке форм развития. </p>

        <div class="base-flex mb-4">
          <pie-chart :data_pie="data_pie" class="plan-desc__pie" />
          <p class="w-50 single__step-description">
            Питание с повышенным содержанием жира (в основном Омега-3 и 1500-2000 калорий), что способствует насыщению и уменьшению потребности в легкоусвояемых углеводах
          </p>
        </div>

        <p class="single__step-title">ФАЗА-2</p>
        <p class="single__content">Значимость этих проблем настолько очевидна, что новая модель организационной деятельности позволяет выполнять важные задания по разработке форм развития. </p>

        <div class="base-flex mb-4">
          <pie-chart :data_pie="data_pie" class="plan-desc__pie" />
          <p class="w-50 single__step-description">
            Питание с повышенным содержанием жира (в основном Омега-3 и 1500-2000 калорий), что способствует насыщению и уменьшению потребности в легкоусвояемых углеводах
          </p>
        </div>

        <p class="single__step-title">ФАЗА-3</p>
        <p class="single__content">Значимость этих проблем настолько очевидна, что новая модель организационной деятельности позволяет выполнять важные задания по разработке форм развития. </p>

        <div class="base-flex mb-4">
          <pie-chart :data_pie="data_pie" class="plan-desc__pie" />
          <p class="w-50 single__step-description">
            Питание с повышенным содержанием жира (в основном Омега-3 и 1500-2000 калорий), что способствует насыщению и уменьшению потребности в легкоусвояемых углеводах
          </p>
        </div>

      </div>
    </div>

  </div>
</template>
<script>
import PieChart from '../../partials/Charts/PieChart'
export default {
  components: {
    PieChart
  },
  data() {
    return {
      data_pie: {
        data:   [10, 20, 30],
        label: 'Pie chart for dish descrition',
        labels: [
          'Жиры',
          'Углеводы',
          'Белки'
        ],
        backgroundColor: [
          '#6C63FF',
          '#00FFF0',
          '#FF00D6',
          '#C6C0FF'
        ],
      }
    }
  }
}
</script>