import Vue from 'vue'
import Router from 'vue-router'

import Welcome from '../components/pages/Welcome'
import SignIn from '../components/pages/SignIn/SignIn'
import ForgotPassword from '../components/pages/SignIn/ForgotPassword'

import RegistrationStep1 from '../components/pages/Registration/Step1'
import RegistrationStep2 from '../components/pages/Registration/Step2'
import RegistrationStep3 from '../components/pages/Registration/Step3'
import RegistrationStep4 from '../components/pages/Registration/Step4'
import RegistrationStep5 from '../components/pages/Registration/Step5'
import RegistrationStep6 from '../components/pages/Registration/Step6'

import Main from '../components/pages/Main'
import ShoppingList from '../components/pages/ShoppingList/ShoppingList'
import Calendar from '../components/pages/Calendar'

import Profile from '../components/pages/Profile/Profile'
import EditProfile from '../components/pages/Profile/EditProfile'
import EditMyRestricts from '../components/pages/Profile/EditRestricts'
import EditMyPreferences from '../components/pages/Profile/EditPreferences'
import EditMyParameters from '../components/pages/Profile/EditParameters'
import EditMyGoal from '../components/pages/Profile/EditMyGoal'
import ChangePassword from "../components/pages/Profile/ChangePassword";
import MyFamily from '../components/pages/Profile/MyFamily'

import Blogs from '../components/pages/Blog/List'
import Blog from '../components/pages/Blog/Single'

import Recipes from '../components/pages/Recipe/List'
import Recipe from '../components/pages/Recipe/Single'
import AddRecipe from '../components/pages/Recipe/AddRecipe'

import DietPlans from '../components/pages/DietPlan/List'
import DietPlan from '../components/pages/DietPlan/Single'
import DietDescription from '../components/pages/DietPlan/Description'

import StatisticsWater from '../components/pages/Statistics/Water'
import StatisticsCalories from '../components/pages/Statistics/Calories'
import StatisticsWeight from '../components/pages/Statistics/Weight'

import Plan from '../components/pages/Plan/Index'

import MolecularAtlas from '../components/pages/MolecularAtlas/index.vue'
import MolecularAtlasSingle from '../components/pages/MolecularAtlas/single'

import Vitamins from '../components/pages/Vitamin/List.vue'
import Vitamin from '../components/pages/Vitamin/Single.vue'

import Basket from '../components/pages/ShoppingList/Basket'

Vue.use(Router)

function requireAuth(to, from, next) {
    if (localStorage.token) {
        next()
    } else {
        next({
            name: 'welcome'
        })
    }
}


export default new Router({
    routes: [
        {
            path: '/',
            name: 'main',
            component: Main,
            props: {
                menu: true
            },
            beforeEnter: requireAuth
        },
        {
            path: '/shopping-list',
            name: 'shopping-list',
            component: ShoppingList,
            props: {
                menu: true
            },
            beforeEnter: requireAuth
        },
        {
            path: '/calendar',
            name: 'calendar',
            component: Calendar,
            props: {
                menu: true
            },
            beforeEnter: requireAuth
        },
        {
            path: '/plan',
            name: 'plan',
            component: Plan,
            props: {
                menu: true
            },
            beforeEnter: requireAuth
        },
        {
            path: '/molecular-health-atlas',
            name: 'atlas',
            component: MolecularAtlas,
            props: {
                menu: true
            },
            beforeEnter: requireAuth
        },
        {
            path: '/molecular-health-atlas/:id',
            name: 'atlas-single',
            component: MolecularAtlasSingle,
            props: {
                menu: true
            },
            beforeEnter: requireAuth
        },
        {
            path: '/basket',
            name: 'basket',
            component: Basket,
            props: {
                menu: false
            },
            beforeEnter: requireAuth
        },
        {
            path: '/diet-plans',
            name: 'diet-plans',
            component: DietPlans,
            props: {
                menu: true
            },
            beforeEnter: requireAuth
        },
        {
            path: '/diet-plan/:id',
            name: 'diet-plan',
            component: DietPlan,
            props: {
                menu: true
            },
            beforeEnter: requireAuth
        },
        {
            path: '/description/:id',
            name: 'description',
            component: DietDescription,
            props: {
                menu: true
            },
            beforeEnter: requireAuth
        },
        {
            path: '/profile',
            name: 'profile',
            component: Profile,
            props: {
                menu: true
            },
            beforeEnter: requireAuth
        },
        {
            path: '/edit-profile',
            name: 'edit-profile',
            component: EditProfile,
            props: {
                menu: false
            },
            beforeEnter: requireAuth
        },
        {
            path: '/profile/edit-restricts',
            name: 'edit-restricts',
            component: EditMyRestricts,
            props: {
                menu: false
            },
            beforeEnter: requireAuth
        },
        {
            path: '/profile/edit-goal',
            name: 'edit-goal',
            component: EditMyGoal,
            props: {
                menu: false
            },
            beforeEnter: requireAuth
        },
        {
            path: '/profile/edit-parameters',
            name: 'edit-parameters',
            component: EditMyParameters,
            props: {
                menu: false
            },
            beforeEnter: requireAuth
        },
        {
            path: '/profile/edit-preferences',
            name: 'edit-preferences',
            component: EditMyPreferences,
            props: {
                menu: false
            },
            beforeEnter: requireAuth
        },
        {
            path: '/profile/change-password',
            name: 'change-password',
            component: ChangePassword,
            props: {
                menu: false
            },
            beforeEnter: requireAuth
        },
        {
            path: '/profile/my-family',
            name: 'my-family',
            component: MyFamily,
            props: {
                menu: true
            },
            beforeEnter: requireAuth
        },
        {
            path: '/blogs',
            name: 'blogs',
            component: Blogs,
            props: {
                menu: true
            },
            beforeEnter: requireAuth
        },
        {
            path: '/blog/:id',
            name: 'blog',
            component: Blog,
            props: {
                menu: true
            },
            beforeEnter: requireAuth
        },
        {
            path: '/recipes',
            name: 'recipes',
            component: Recipes,
            props: {
                menu: true
            },
            beforeEnter: requireAuth
        },
        {
            path: '/recipe/:id',
            name: 'recipe',
            component: Recipe,
            props: {
                menu: true
            },
            beforeEnter: requireAuth
        },
        {
            path: '/add-recipe',
            name: 'add-recipe',
            component: AddRecipe,
            props: {
                menu: true
            },
            beforeEnter: requireAuth
        },
        {
            path: '/vitamins',
            name: 'vitamins',
            component: Vitamins,
            props: {
                menu: true
            },
            beforeEnter: requireAuth
        },
        {
            path: '/vitamin/:id',
            name: 'vitamin',
            component: Vitamin,
            props: {
                menu: true
            },
            beforeEnter: requireAuth
        },
        {
            path: '/statistics-water',
            name: 'statistics-water',
            component: StatisticsWater,
            props: {
                menu: true
            },
            beforeEnter: requireAuth
        },
        {
            path: '/statistics-calories',
            name: 'statistics-calories',
            component: StatisticsCalories,
            props: {
                menu: true
            },
            beforeEnter: requireAuth
        },
        {
            path: '/statistics-weight',
            name: 'statistics-weight',
            component: StatisticsWeight,
            props: {
                menu: true
            },
            beforeEnter: requireAuth
        },
        {
            path: '/welcome',
            name: 'welcome',
            component: Welcome,
            props: {
                menu: false
            }
        },
        {
            path: '/sign-in',
            name: 'sign-in',
            component: SignIn,
            props: {
                menu: false
            }
        },
        {
            path: '/forgot-password',
            name: 'forgot-password',
            component: ForgotPassword,
            props: {
                menu: false
            }
        },
        {
            path: '/registration-step-1',
            name: 'registration-step-1',
            component: RegistrationStep1,
            props: {
                menu: false
            }
        },
        {
            path: '/registration-step-2',
            name: 'registration-step-2',
            component: RegistrationStep2,
            props: {
                menu: false
            }
        },
        {
            path: '/registration-step-3',
            name: 'registration-step-3',
            component: RegistrationStep3,
            props: {
                menu: false
            }
        },
        {
            path: '/registration-step-4',
            name: 'registration-step-4',
            component: RegistrationStep4,
            props: {
                menu: false
            }
        },
        {
            path: '/registration-step-5',
            name: 'registration-step-5',
            component: RegistrationStep5,
            props: {
                menu: false
            }
        },
        {
            path: '/registration-step-6',
            name: 'registration-step-6',
            component: RegistrationStep6,
            props: {
                menu: false
            }
        },
        {
            path: '*',
            redirect: '/'
        }
    ],
    mode: 'history',
    linkExactActiveClass: 'exact-active',
    linkActiveClass: 'active',
    // eslint-disable-next-line no-unused-vars
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
})
