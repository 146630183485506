import {HTTP} from '@/http'

class Preferences {

    static list(then, catcher, params = null) {
        return HTTP.get('/limitations', params)
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }

}
export default Preferences
