import {HTTP} from '@/http'

class Auth {

    static register(then, catcher, params = null) {
        return HTTP.post('/register', params)
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }

    static login(then, catcher, params = null) {
        return HTTP.post('/login', params)
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }

    static logout(then, catcher, params = null) {
        setTimeout(() => {
            return HTTP.post('/logout', params)
                .then(({data}) => then(data))
                .catch((errors) => catcher(errors))
        })
    }
    static resetPassword(then, catcher, params = null) {
        return HTTP.post('/reset-password', params)
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }

}
export default Auth
