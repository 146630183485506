import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)


import registration from '@/store/modules/auth'
import staticFunctions from "@/store/modules/static";
import dish from "@/store/modules/dish";
import preloader from "@/store/modules/preloader";
export const store = new Vuex.Store({
    modules: {
        registration,
        staticFunctions,
        dish,
        preloader
    }
})