import { Pie } from 'vue-chartjs'

export default {
    extends: Pie,
    props: ['data_pie'],
    mounted () {
        this.renderChart({
            labels: this.data_pie.labels,
            datasets: [
                {
                    backgroundColor: this.data_pie.backgroundColor,
                    data: this.data_pie.data
                }
            ]
        }, {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                position: 'bottom',
                align: 'start',
                labels: {
                    boxWidth: 10,
                    fontSize: 12,
                    fontFamily: 'FuturaPT'
                }
            }
        })
    }
}
