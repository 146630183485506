<template>
  <div class="atlas atlas-single">
    <router-link :to="{name: 'atlas'}" class="atlas__title pt-4 pb-3">
      <img src="../../../assets/images/arrow-left-to-back.svg" alt="">
      Молекулярный атлас
    </router-link>

    <ul class="atlas-single__tab mb-3">
      <li @click="getReview" :class="[ review ? 'active' : '']">Свойтсво</li>
      <li @click="getProducts" :class="[ products ? 'active' : '']">Продукты</li>
    </ul>
    <template v-if="review">
      <div class="atlas-single__banner">
        <img src="../../../assets/images/single-banner.jpg" alt="">
      </div>
      <div class="atlas-single__top">
        <p class="atlas-single__title">Витамин А</p>
        <button class="atlas-single__btn">
          Витамин
        </button>
      </div>
      <p class="atlas-single__text">Витамин А - жирорастворимый  витамин необходимый для поддрежания здорового зрения, обеспечения нормально работы вашей иммунной системы и органов, содействия правильному росту и развитию организма в утробе матери. </p>
      <p class="atlas-single__subtitle">Нормы потребления</p>
      <div class="table-responsive full-width">
        <table class="table table-borderless table-striped atlas-single__table">
          <thead>
          <tr>
            <th> Возраст </th>
            <th> Рацион </th>
            <th> Добавки </th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>18-60 лет</td>
            <td>450 RAE</td>
            <td>-</td>
          </tr>
          <tr>
            <td>18-60 лет</td>
            <td>450 RAE</td>
            <td>-</td>
          </tr>
          <tr>
            <td>18-60 лет</td>
            <td>450 RAE</td>
            <td>-</td>
          </tr>
          </tbody>
        </table>
      </div>

      <p class="atlas-single__subtitle">Ваши показатели</p>
      <div class="table-responsive full-width">
        <table class="table table-borderless table-striped atlas-single__table">
          <thead>
          <tr>
            <th> Пункт </th>
            <th> Показатели </th>
            <th> Норма </th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>18-60 лет</td>
            <td>450 RAE</td>
            <td>-</td>
          </tr>
          <tr>
            <td>18-60 лет</td>
            <td>450 RAE</td>
            <td>-</td>
          </tr>
          <tr>
            <td>18-60 лет</td>
            <td>450 RAE</td>
            <td>-</td>
          </tr>
          </tbody>
        </table>
      </div>
    </template>
    <template v-if="products">
      <div class="atlas-card__bound">
        <div class="atlas-card">
          <div class="atlas-card__block">
            <p class="atlas-card__title">Утиная печень, сырая</p>
            <p class="atlas-card__text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed a, turpis laoreet lectus facilisis ipsum, ut cursus.
              Feugiat placerat porttitor rutrum gravida elit...
            </p>
            <p class="atlas-card__info">
              <span class="text-success">11984 RAE</span>  / 100 г.
            </p>
          </div>
          <img src="./../../../assets/images/vitamin.jpg" alt="" class="atlas-card__image">
        </div>
        <div class="atlas-card__link">
          <router-link  :to="{name: 'atlas-single', params: {id: 1}}" >В меню</router-link>
        </div>
      </div>
      <div class="atlas-card__bound">
        <div class="atlas-card">
          <div class="atlas-card__block">
            <p class="atlas-card__title">Утиная печень, сырая</p>
            <p class="atlas-card__text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed a, turpis laoreet lectus facilisis ipsum, ut cursus.
              Feugiat placerat porttitor rutrum gravida elit...
            </p>
            <p class="atlas-card__info">
              <span class="text-warning-base">11984 RAE</span>  / 100 г.
            </p>
          </div>
          <img src="./../../../assets/images/vitamin.jpg" alt="" class="atlas-card__image">
        </div>
        <div class="atlas-card__link">
          <router-link  :to="{name: 'atlas-single', params: {id: 1}}" >В меню</router-link>
        </div>
      </div>

    </template>
  </div>
</template>
<script>
export default {
  components: {
    // Preloader
  },
  data(){
    return {
      data: null,
      errors: null,
      review: true,
      products: false
    }
  },
  mounted() {
  },
  methods: {
    getReview() {
      this.review = true
      this.products = false
    },
    getProducts() {
      this.review = false
      this.products = true
    }
  }
}
</script>