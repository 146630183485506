<template>
  <div class="container-ul advance">
    <div class="main__top">
      <button class="main__top__arrow" @click="previousDay"><img src="./../../../assets/images/left.svg" alt=""></button>
      <p class="main__top__title">{{ date }}</p>
      <button class="main__top__arrow" @click="nextDay"><img src="./../../../assets/images/right.svg" alt=""></button>
    </div>

    <p class="advance__title"><span class="amount">75.5</span> кг</p>


    <ul class="period-list">
      <li class="active">Неделя</li>
      <li>Месяц</li>
      <li>Год</li>
    </ul>

    <ChartWeight :data_line="line_chart_data" class="calories-bar-chart-resize"></ChartWeight>

    <p class="advance__subtitle pt-4">Анализ</p>

    <div class="waves">
      <div>
        <div class="wave">
          <p class="wave__title highlight-weight">Завтрак</p>
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
               viewBox="0 0 524 320" style="enable-background:new 0 0 1024 320;" xml:space="preserve">
          <path id="mask" fill="#FB3C97"/>
            <animate
                xlink:href="#mask"
                attributeName="d"
                attributeType="XML"
                values=" M-0.5,319.5c0-60.7,0-121.3,0-182c25.4-57.6,52.8-74.7,74-79c70-14.2,117,102.1,202,93
                  c52.9-5.7,51.9-52.4,116-65c70.4-13.8,90.3,38.9,173,35c78.9-3.8,84.4-52.9,155-51c74.3,2,93.9,56.9,166,61
                  c58.5,3.3,107.6-29.4,138-55c0,81,0,162,0,243;
                M-0.5,319.5c0-60.7,0-121.3,0-182c25.4-57.6,101.8-69.7,123-74c70-14.2,69,136.1,154,127
                  c52.9-5.7,79.9-130.4,144-143c70.4-13.8,85.3,138.9,168,135c78.9-3.8,97.4-137.9,168-136c74.3,2,79.9,139.9,152,144
                  c58.5,3.3,84.6-88.4,115-114c0,81,0,162,0,243;
                M-0.5,319.5c0-60.7,0-121.3,0-182c25.4-57.6,153.8-88.7,175-93c70-14.2,23.1,112.9,102,146
                  c69,29,89.9-195.4,117-136c41,90,141.3,191,195,128c35-41,98.6-149.5,168-136c206,40,79.9,139.9,152,144c58.5,3.3,84.6-88.4,115-114
                  c0,81,0,162,0,243;
                M0,320c0-60.1,0-120.3,0-180.4c11.4-25.5,31.5-58.7,67.1-75c75-34.3,132.9,45.8,256.5,45
                  c106.2-0.7,101-60.1,208.5-57c102.1,3,133.2,57.4,228,37.5c40.3-8.5,61.2-23.9,99-15c21.2,5,46.4,22.9,96,58.5
                  c4.5,3.3,68.8,49.4,68.9,49.5c0,45.6,0,91.3,0,136.9;
                M-0.5,319.5c0-60.7,0-121.3,0-182c25.4-57.6,52.8-74.7,74-79c70-14.2,117,102.1,202,93
                  c52.9-5.7,51.9-52.4,116-65c70.4-13.8,90.3,38.9,173,35c78.9-3.8,84.4-52.9,155-51c74.3,2,93.9,56.9,166,61
                  c58.5,3.3,107.6-29.4,138-55c0,81,0,162,0,243"
                dur="4s"
                repeatCount="indefinite"/>
        </svg>
        </div>
        <p class="wave__desc"><span class="text-bold">300 </span>ккал</p>
      </div>
      <div>
        <div class="wave">
          <p class="wave__title highlight-weight">Обед</p>
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
               viewBox="0 0 524 320" style="enable-background:new 0 0 1024 320;" xml:space="preserve">
          <path id="mask2" fill="#FB3C97"/>
            <animate
                xlink:href="#mask2"
                attributeName="d"
                attributeType="XML"
                values=" M-0.5,319.5c0-60.7,0-121.3,0-182c25.4-57.6,52.8-74.7,74-79c70-14.2,117,102.1,202,93
                  c52.9-5.7,51.9-52.4,116-65c70.4-13.8,90.3,38.9,173,35c78.9-3.8,84.4-52.9,155-51c74.3,2,93.9,56.9,166,61
                  c58.5,3.3,107.6-29.4,138-55c0,81,0,162,0,243;
                M0,320c0-60.1,0-120.3,0-180.4c11.4-25.5,31.5-58.7,67.1-75c75-34.3,132.9,45.8,256.5,45
                  c106.2-0.7,101-60.1,208.5-57c102.1,3,133.2,57.4,228,37.5c40.3-8.5,61.2-23.9,99-15c21.2,5,46.4,22.9,96,58.5
                  c4.5,3.3,68.8,49.4,68.9,49.5c0,45.6,0,91.3,0,136.9;
                M-0.5,319.5c0-60.7,0-121.3,0-182c25.4-57.6,153.8-88.7,175-93c70-14.2,23.1,112.9,102,146
                  c69,29,89.9-195.4,117-136c41,90,141.3,191,195,128c35-41,98.6-149.5,168-136c206,40,79.9,139.9,152,144c58.5,3.3,84.6-88.4,115-114
                  c0,81,0,162,0,243;
                M-0.5,319.5c0-60.7,0-121.3,0-182c25.4-57.6,101.8-69.7,123-74c70-14.2,69,136.1,154,127
                  c52.9-5.7,79.9-130.4,144-143c70.4-13.8,85.3,138.9,168,135c78.9-3.8,97.4-137.9,168-136c74.3,2,79.9,139.9,152,144
                  c58.5,3.3,84.6-88.4,115-114c0,81,0,162,0,243;
                M-0.5,319.5c0-60.7,0-121.3,0-182c25.4-57.6,52.8-74.7,74-79c70-14.2,117,102.1,202,93
                  c52.9-5.7,51.9-52.4,116-65c70.4-13.8,90.3,38.9,173,35c78.9-3.8,84.4-52.9,155-51c74.3,2,93.9,56.9,166,61
                  c58.5,3.3,107.6-29.4,138-55c0,81,0,162,0,243"
                dur="4s"
                repeatCount="indefinite"/>
        </svg>
        </div>
        <p class="wave__desc"><span class="text-bold">1200 </span>ккал</p>
      </div>
      <div>
        <div class="wave">
          <p class="wave__title highlight-weight">Ужин</p>
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
               viewBox="0 0 524 320" style="enable-background:new 0 0 1024 320;" xml:space="preserve">
          <path id="mask3" fill="#FB3C97"/>
            <animate
                xlink:href="#mask3"
                attributeName="d"
                attributeType="XML"
                values=" M0,320c0-60.1,0-120.3,0-180.4c11.4-25.5,31.5-58.7,67.1-75c75-34.3,132.9,45.8,256.5,45
                  c106.2-0.7,101-60.1,208.5-57c102.1,3,133.2,57.4,228,37.5c40.3-8.5,61.2-23.9,99-15c21.2,5,46.4,22.9,96,58.5
                  c4.5,3.3,68.8,49.4,68.9,49.5c0,45.6,0,91.3,0,136.9;

                M-0.5,319.5c0-60.7,0-121.3,0-182c25.4-57.6,52.8-74.7,74-79c70-14.2,117,102.1,202,93
                  c52.9-5.7,51.9-52.4,116-65c70.4-13.8,90.3,38.9,173,35c78.9-3.8,84.4-52.9,155-51c74.3,2,93.9,56.9,166,61
                  c58.5,3.3,107.6-29.4,138-55c0,81,0,162,0,243;

                M-0.5,319.5c0-60.7,0-121.3,0-182c25.4-57.6,101.8-69.7,123-74c70-14.2,69,136.1,154,127
                  c52.9-5.7,79.9-130.4,144-143c70.4-13.8,85.3,138.9,168,135c78.9-3.8,97.4-137.9,168-136c74.3,2,79.9,139.9,152,144
                  c58.5,3.3,84.6-88.4,115-114c0,81,0,162,0,243;

                M0,320c0-60.1,0-120.3,0-180.4c11.4-25.5,31.5-58.7,67.1-75c75-34.3,132.9,45.8,256.5,45
                  c106.2-0.7,101-60.1,208.5-57c102.1,3,133.2,57.4,228,37.5c40.3-8.5,61.2-23.9,99-15c21.2,5,46.4,22.9,96,58.5
                  c4.5,3.3,68.8,49.4,68.9,49.5c0,45.6,0,91.3,0,136.9;"
                dur="4s"
                repeatCount="indefinite"/>
        </svg>
        </div>
        <p class="wave__desc"><span class="text-bold">700 </span>ккал</p>
      </div>


    </div>





  </div>
</template>
<script>
import ChartWeight from '../../partials/Charts/WeightLineChart'
export default {
  components: {
    ChartWeight
  },
  data () {
    return {
      date: null,
      date_for_select: null,
      line_chart_data: {
        labels: ['oct', 'Dec', 'Feb', 'May', 'Jul'],
        datasets: {
          label: ' Вес за период',
          data: [58, 55, 54, 60, 54],
        },
      },

    }
  },
  mounted() {
    this.currentDay()
  },
  methods: {
    addWater(){
      this.water_progress.drunk ++
      this.water_progress.have_to --
    },
    goToNextPage() {
      this.$router.push({ name: 'sign-in' })
    },
    currentDay () {
      let currentDate = new Date();
      this.formatDate(currentDate)
    },
    nextDay() {
      let current_day = new Date(this.date_for_select);
      let nextDay = new Date(current_day);
      nextDay.setDate(current_day.getDate() + 1);
      this.formatDate(nextDay)
    },
    previousDay() {
      let current_day = new Date(this.date_for_select);
      let previousDay = new Date(current_day);
      previousDay.setDate(current_day.getDate() - 1);
      this.formatDate(previousDay)
    },
    formatDate(date){
      let formatted = date.toLocaleString('ru-RU', { day: '2-digit', month: 'long', year: 'numeric' })
      this.date = formatted
      this.date_for_select = date
    },
  }
}
</script>
<style>
