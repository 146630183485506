// import DailyRation from "@/api/DailyRation";
// import Recipe from "@/api/Recipe";
import ReplaceDish from "@/api/ReplaceDish";
const state = {
    dish_id: null,
    plan_id: null,
    dish_status: null,
    data: null,
    errors: null,
    modal: false,
    modal_data: null,
}

const getters = {
    dish_status: state => () => state.dish_status,
    getModal: state => () => state.modal,
    getModalData: state => () => state.modal_data
}

const mutations = {
    REPLACE_DISH(state, payload) {
        let params = {
            plan_id: state.plan_id,
            dish_id: payload.dish_id
        }
        ReplaceDish.replaceDish(data => state.data = data, errors => state.errors = errors, params)
            .then(() => {
                state.modal = false
                state.dish_status = true
            })
    },
    GET_MODAL_DATA(state, payload) {
        state.modal = true
        state.plan_id = payload.plan_id
        let params = {
            plan_id: payload.plan_id
        }
        ReplaceDish.getRecommendedForReplace(data => state.modal_data = data, errors => state.errors = errors, params)

    },
    GET_RECOMMENDED_DATA(state) {
        let params = {
            plan_id: state.plan_id
        }
        ReplaceDish.getRecommendedForReplace(data => state.modal_data = data, errors => state.errors = errors, params)

    },
    GET_FAVOURITE_DATA(state) {
        let params = {
            plan_id: state.plan_id
        }
        ReplaceDish.getFavouriteForReplace(data => state.modal_data = data.data, errors => state.errors = errors, params)
    },
    SEARCH_FOR_MODAL_DATA(state, payload) {
        let params = {
            plan_id: state.plan_id,
            name: payload.name
        }
        ReplaceDish.getRecommendedForReplace(data => state.modal_data = data, errors => state.errors = errors, params)

    },
    CLOSE_MODAL(state) {
        state.modal = false
    }
}

const actions = {
    GET_RECOMMENDED_DATA: ({commit}) => {
        commit('GET_RECOMMENDED_DATA')
    },
    GET_FAVOURITE_DATA: ({commit}) => {
        commit('GET_FAVOURITE_DATA')
    },
    TO_REPLACE_DISH: ({commit}, payload) => {
        commit('GET_MODAL_DATA', payload)
    },
    SEARCH_FOR_MODAL_DATA: ({commit}, payload) => {
        commit('SEARCH_FOR_MODAL_DATA', payload)
    },
    CLOSE_MODAL: ({commit}, payload = null) => {
        commit('CLOSE_MODAL', payload)
    },
    DISH_CHOSEN: ({commit}, payload) => {
        commit('REPLACE_DISH', payload)
    },
}


export default {
    state,
    getters,
    actions,
    mutations
}
