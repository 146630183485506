<template>
  <div class="breakfast-swiper">
      <Slick
          ref="slick"
          :options="swiper_options" >
        <BlockLarge v-for="(item, index) in list_dishes" :key="'breakfast'+index"  :data="item"/>
      </Slick>
  </div>
</template>
<script>
import Slick from 'vue-slick';
import BlockLarge from '../BlockLarge'
export default {
  components: {
    Slick,
    BlockLarge
  },
  props: ['list_dishes'],
  data() {
    return {
      swiper_options: {
        slidesToShow: 1,
        arrows: false,
        dots: true,
        infinite: false
      }
    }
  },
}
</script>