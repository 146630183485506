<template>
  <div class="main diet-plans" v-if="diets">
    <div class="container-ul">
      <router-link
          v-for="(item, index) in diets.data"
          :key="index"
          :to="{name: 'diet-plan', params: {id: item.id}}"
          class="diet-plans__block"
      >
        <img :src="item.image" alt="" class="diet-plans__block__img">
        <div class="abs-full-block gradient-bg-blue">
          <p>{{ item.name }}</p>
          <p class="diet-plans__block__price">  ₸ {{ item.price }} </p>
        </div>
      </router-link>

      <paginate
          :page-count="Math.ceil(diets.meta.total/diets.meta.per_page)"
          :click-handler="onPaginate"
          :prev-text="'<'"
          :next-text="'>'"
          :container-class="'pagination'">
      </paginate>

    </div>
  </div>
</template>
<script>
import Diet from "@/api/Diet";
export default {
   data(){
     return {
       diets: null,
       errors: null,
       page: 1,
       per_page: 5,
     }
   },
  watch: {
    page () {
      this.getBlogs()
    }
  },
  mounted() {
     this.getDiets()
  },
  methods:{
    getDiets(){
     let params = {
       page: this.page,
       per_page: this.per_page
     }
     Diet.list(data=> this.diets = data, errors=>this.errors = errors, params)
    },
    onPaginate (page) {
      this.page = page
    }
  }
}
</script>