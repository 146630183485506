<template>
  <div class="main">
    <div class="container-ul">
      <form class="input-group-with-shadow  mt-4" @submit.prevent="searchVitamins">
        <input
            type="text"
            class="input-with-shadow form-control"
            placeholder="Искать по названию"
            aria-label="Recipient's username"
            v-model="to_search"
        >
        <div class="addon-with-shadow">
          <button type="submit">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M9.67232 10.8477L12.2473 13.4193C12.5723 13.7493 13.0973 13.7493 13.4223 13.4193C13.749 13.0902 13.749 12.5577 13.4223 12.2277L10.8556 9.661C11.5807 8.68766 12.0115 7.481 12.0115 6.17266C12.0115 2.94766 9.39732 0.333496 6.17315 0.333496C2.94815 0.333496 0.333984 2.94766 0.333984 6.17266C0.333984 9.39766 2.94815 12.0118 6.17315 12.0118C7.48565 12.0118 8.69732 11.5785 9.67232 10.8477ZM6.17232 10.3435C3.86982 10.3435 2.00232 8.47683 2.00232 6.17266C2.00232 3.86933 3.86898 2.00183 6.17315 2.00183C8.47648 2.00183 10.3432 3.8685 10.3432 6.17266C10.3432 8.476 8.47648 10.3435 6.17398 10.3435H6.17232Z" fill="#181818"/>
            </svg>
          </button>
        </div>
      </form>
      <div class="input-group-with-shadow mb-3" >
        <div
            class="input-with-shadow form-control"
            aria-label="Recipient's username"
            aria-describedby="basic-addon2"
        > Фильтры </div>
        <div class="addon-with-shadow">
          <button>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.75 5H16.1875C15.875 3.5625 14.625 2.5 13.125 2.5C11.625 2.5 10.375 3.5625 10.0625 5H1.25V6.25H10.0625C10.375 7.6875 11.625 8.75 13.125 8.75C14.625 8.75 15.875 7.6875 16.1875 6.25H18.75V5ZM13.125 7.5C12.0625 7.5 11.25 6.6875 11.25 5.625C11.25 4.5625 12.0625 3.75 13.125 3.75C14.1875 3.75 15 4.5625 15 5.625C15 6.6875 14.1875 7.5 13.125 7.5Z" fill="#8212FC"/>
              <path d="M1.25 15H3.8125C4.125 16.4375 5.375 17.5 6.875 17.5C8.375 17.5 9.625 16.4375 9.9375 15H18.75V13.75H9.9375C9.625 12.3125 8.375 11.25 6.875 11.25C5.375 11.25 4.125 12.3125 3.8125 13.75H1.25V15ZM6.875 12.5C7.9375 12.5 8.75 13.3125 8.75 14.375C8.75 15.4375 7.9375 16.25 6.875 16.25C5.8125 16.25 5 15.4375 5 14.375C5 13.3125 5.8125 12.5 6.875 12.5Z" fill="#8212FC"/>
            </svg>
          </button>
        </div>
      </div>
      <p class="base-title-bold base-title-bold--30">Витамины</p>
      <div class="vitamin-card__bound">
        <div class="vitamin-card">
          <div class="vitamin-card__block">
            <p class="vitamin-card__title">
              <span>B</span>
              Витамин К
            </p>
            <p class="vitamin-card__text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed a, turpis laoreet lectus facilisis ipsum, ut cursus.
              Feugiat placerat porttitor rutrum gravida elit...
              <router-link  :to="{name: 'vitamin', params: {id: 1}}" class="vitamin-card__more">Читать далее.</router-link>
            </p>
            <p class="vitamin-card__info">
              Блюда богатые <span>Витамином К</span>: <br>
              Блюдо1, Блюдо2, Блюдо3, Блюдо4.
            </p>
          </div>
          <img src="./../../../assets/images/vitamin.jpg" alt="" class="vitamin-card__image">
        </div>
        <div class="vitamin-card__link">
          <router-link  :to="{name: 'vitamin', params: {id: 1}}" >Все блюда</router-link>
        </div>
      </div>

      <div class="vitamin-card__bound">
        <div class="vitamin-card">
          <div class="vitamin-card__block">
            <p class="vitamin-card__title">
              <span>B</span>
              Витамин К
            </p>
            <p class="vitamin-card__text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed a, turpis laoreet lectus facilisis ipsum, ut cursus.
              Feugiat placerat porttitor rutrum gravida elit...
              <router-link  :to="{name: 'vitamin', params: {id: 1}}" class="vitamin-card__more">Читать далее.</router-link>
            </p>
            <p class="vitamin-card__info">
              Блюда богатые <span>Витамином К</span>: <br>
              Блюдо1, Блюдо2, Блюдо3, Блюдо4.
            </p>
          </div>
          <img src="./../../../assets/images/vitamin.jpg" alt="" class="vitamin-card__image">
        </div>
        <div class="vitamin-card__link">
          <router-link  :to="{name: 'vitamin', params: {id: 1}}" >Все блюда</router-link>
        </div>
      </div>

      <div class="vitamin-card__bound">
        <div class="vitamin-card">
          <div class="vitamin-card__block">
            <p class="vitamin-card__title">
              <span>B</span>
              Витамин К
            </p>
            <p class="vitamin-card__text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed a, turpis laoreet lectus facilisis ipsum, ut cursus.
              Feugiat placerat porttitor rutrum gravida elit...
              <router-link  :to="{name: 'vitamin', params: {id: 1}}" class="vitamin-card__more">Читать далее.</router-link>
            </p>
            <p class="vitamin-card__info">
              Блюда богатые <span>Витамином К</span>: <br>
              Блюдо1, Блюдо2, Блюдо3, Блюдо4.
            </p>
          </div>
          <img src="./../../../assets/images/vitamin.jpg" alt="" class="vitamin-card__image">
        </div>
        <div class="vitamin-card__link">
          <router-link  :to="{name: 'vitamin', params: {id: 1}}" >Все блюда</router-link>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
export default {
  components: {
  },
  data () {
    return {
      to_search: null,
      to_filter: []
    }
  },
  mounted() {

  },
  computed: {

  },
  watch: {

  },
  methods: {
    searchVitamins() {
      console.log('search')
    },
    makeFilter() {
      console.log('make filter')
    }
  }
}
</script>