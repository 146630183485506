<template>
  <div class="goal-step abs-full-block profile">
    <div class="sign-in__flex">
      <form @submit.prevent="updateData" class="w-100">
        <p class="goal-step__title"> Изменить пороль</p>


        <label class="base-label">Старый пароль </label>
        <input class="base-input" type="password"  placeholder="" v-model="old_password" required>

        <label class="base-label">Новый пароль</label>
        <input class="base-input" type="password"  placeholder="" v-model="new_password" required>


        <div class="w-100">
          <button   type="submit" class="base-btn base-btn--violet mt-4">Сохранить</button>
          <router-link :to="{name: 'profile'}" class="forgot-password mb-3">Отменить</router-link>
        </div>



      </form>

    </div>
  </div>
</template>
<script>
import Profile from "@/api/Profile";

export default {
  data() {
    return {
      old_password: null,
      new_password: null
    }
  },
  methods: {
    updateData() {
      let params = {
        old_password: this.old_password,
        new_password: this.new_password
      }
      Profile.changePassword(data => this.data = data, errors => this.errors = errors, params)
          .then(() => {
            this.$store.dispatch('SHOW_ALERT', [this.data.message])
            setTimeout(() => {
              this.$router.push({ name: 'profile' })
            }, 0)
          })
    }
  }
}
</script>