<template>
  <div class="welcome">
    <div class="abs-full-block welcome__flex gradient-bg">
      <div>
        <div class="welcome__logo"><img src="./../../assets/images/nomadiet-logo.png" alt=""></div>
        <h1 class="welcome__title">NutriSteppe</h1>
      </div>
      <div>
        <p class="welcome__subtitle">Присоединяйся</p>
        <p class="welcome__info">научно обоснованный путь к устойчивому управлению весом </p>
      </div>
      <div>
        <router-link :to="{ name: 'sign-in' }" class="base-btn base-btn--gradient">Войти</router-link>
        <router-link :to="{ name: 'registration-step-1' }" class="base-btn base-btn--white"  @click="goToNextPage">Регистрация</router-link>
      </div>
    </div>
  </div>
</template>
   <script>
export default {
  methods: {
    goToNextPage() {
       this.$router.push({ name: 'sign-in' })
    }
  }
}
</script>