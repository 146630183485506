<template>
  <div class="main">
    <div class="container-ul" >
      <div class="main__top">
<!--        <button class="main__top__arrow" @click="previousDay"><img src="./../../assets/images/left.svg" alt=""></button>-->
        <p class="main__top__title text-center w-100">{{ date }}</p>
<!--        <button class="main__top__arrow" @click="nextDay"><img src="./../../assets/images/right.svg" alt=""></button>-->
      </div>

      <template v-if="statistics">
        <StatisticsSwiper :data="statistics.statistics" />
      </template>
      <Preloader v-else></Preloader>

      <button class="generate-new-menu mt-4" @click="getDayPlanGenerate">Сгенерировать новое меню на день</button>

      <div class="pt-4 pb-3">
        <p class="base-title">План питания</p>
        <div class="base-tab pt-3">
          <button :class="['base-tab__item', today ? 'active' : null ]"  @click="getDayPlan">Сегодня</button>
          <button :class="['base-tab__item', week ? 'active' : null ]" @click="getWeekPlan">На неделю</button>
        </div>
      </div>

      <template v-if="today">

        <div class="base-flex-title">
          <h1 class="base-title">Завтрак</h1>
          <!--          <router-link :to="{name: 'recipes'}" class="base-link">+  Добавить блюдо</router-link>-->
        </div>
        <template v-if="data">
          <template v-if="data['breakfast_dishes']">
            <PeriodDishes :list_dishes="data['breakfast_dishes']" />
          </template>
        </template>
        <Preloader v-else></Preloader>

        <!-- <template v-if="data['snack_one_dishes']"> -->

        <template v-if="data">
          <template v-if="data['snack_one_dishes'].length">
            <div class="base-flex-title">
              <h1 class="base-title">Перекус</h1>
          <!--          <router-link :to="{name: 'recipes'}" class="base-link">+  Добавить блюдо</router-link>-->
            </div>
            <PeriodDishes :list_dishes="data['snack_one_dishes']" />
          </template>
        </template>
        <Preloader v-else></Preloader>

        <!-- </template> -->

        <div class="base-flex-title">
          <h1 class="base-title">Обед</h1>
          <!--          <router-link :to="{name: 'recipes'}" class="base-link">+  Добавить блюдо</router-link>-->
        </div>
        <template v-if="data">
          <template v-if="data['lunch_dishes']">
            <PeriodDishes :list_dishes="data['lunch_dishes']" />
          </template>
        </template>
        <Preloader v-else></Preloader>


        <template v-if="data">
          <template v-if="data['snack_two_dishes'].length">
            <div class="base-flex-title">
              <h1 class="base-title">Перекус</h1>
          <!--          <router-link :to="{name: 'recipes'}" class="base-link">+  Добавить блюдо</router-link>-->
            </div>
            <PeriodDishes :list_dishes="data['snack_two_dishes']" />
          </template>
        </template>
        <Preloader v-else></Preloader>


        <div class="base-flex-title">
          <h1 class="base-title">Ужин</h1>
          <!--          <router-link :to="{name: 'recipes'}" class="base-link">+  Добавить блюдо</router-link>-->
        </div>
        <template v-if="data">
          <template v-if="data['dinner_dishes']">
            <PeriodDishes :list_dishes="data['dinner_dishes']" />
          </template>
        </template>
        <Preloader v-else></Preloader>

        
        <template v-if="data">
          <template v-if="data['snack_three_dishes'].length">
            <div class="base-flex-title">
              <h1 class="base-title">Перекус</h1>
          <!--          <router-link :to="{name: 'recipes'}" class="base-link">+  Добавить блюдо</router-link>-->
            </div>
            <PeriodDishes :list_dishes="data['snack_three_dishes']" />
          </template>
        </template>
        <Preloader v-else></Preloader>


       
      </template>

      <template v-if="week">
        <template v-if="week_plan">
          <div v-for="(item, index) in week_plan.weekly_plan" :key="'week-plan'+index">
            <p class="base-title pt-4 pb-0 mb-0">{{item.day}}</p>

            <p class="text-bold mb-1 mt-2 base-text-medium">Завтрак</p>
            <div v-for="(dish, dish_index) in item.breakfast" :key="'dish000'+dish_index">
              <SmallBlock :dish="dish"></SmallBlock>
            </div>

            <template v-if="item.snack_one.length">
              <p class="text-bold mb-1 mt-2 base-text-medium">Перекус</p>
              <div v-for="(dish, dish_index) in item.snack_one" :key="'dish333'+dish_index">
                <SmallBlock :dish="dish"></SmallBlock>
              </div>
            </template>

            <p class="text-bold mb-1 mt-2 base-text-medium">Обед</p>
            <div class="block-small mb-3" v-for="(dish, dish_index) in item.lunch" :key="'dish111'+dish_index">
              <SmallBlock :dish="dish"></SmallBlock>
            </div>

            <template v-if="item.snack_two.length">
              <p class="text-bold mb-1 mt-2 base-text-medium">Перекус</p>
              <div v-for="(dish, dish_index) in item.snack_two" :key="'dish444'+dish_index">
                <SmallBlock :dish="dish"></SmallBlock>
              </div>
            </template>

            <p class="text-bold mb-1 mt-2 base-text-medium">Ужин</p>
            <div class="block-small mb-3" v-for="(dish, dish_index) in item.dinner" :key="'dish222'+dish_index">
              <SmallBlock :dish="dish"></SmallBlock>
            </div>

            <template v-if="item.snack_three.length">
              <p class="text-bold mb-1 mt-2 base-text-medium">Перекус</p>
              <div v-for="(dish, dish_index) in item.snack_three" :key="'dish555'+dish_index">
                <SmallBlock :dish="dish"></SmallBlock>
              </div>
            </template>


          </div>
        </template>
        <Preloader v-else></Preloader>
      </template>


      <template v-if="blogs">
        <div class="base-flex-title">
          <h1 class="base-title">Блог</h1>
          <router-link :to="{name: 'blogs'}" class="base-link">Все статьи</router-link>
        </div>
        <BlogSwiper :blogs="blogs.data"/>
      </template>
      <Preloader v-else></Preloader>

<!--      <template v-if="diets">-->
<!--        <div class="base-flex-title">-->
<!--          <h1 class="base-title">Планы диет</h1>-->
<!--          <router-link :to="{name: 'diet-plans'}" class="base-link">Все диеты</router-link>-->
<!--        </div>-->
<!--        <PlanSwiper :data="diets.data"/>-->
<!--      </template>-->
<!--      <Preloader v-else></Preloader>-->

    </div>


  </div>
</template>
<script>
import StatisticsSwiper from "../partials/Swiper/StatisticsSwiper"
import PeriodDishes from "../partials/Swiper/PeriodDishes";
import BlogSwiper from "../partials/Swiper/BlogSwiper";
// import PlanSwiper from "../partials/Swiper/PlanSwiper";
import Preloader from '../static/Preloader';
import SmallBlock from "@/components/partials/SmallBlock";
// API
import DailyRation from "@/api/DailyRation";
import Blog from "@/api/Blog";
// import Recipe from "@/api/Recipe";
import Statistics from "@/api/Statistics";
// import Diet from "@/api/Diet";
import moment from "moment";
export default {
  components: {
    StatisticsSwiper,
    PeriodDishes,
    BlogSwiper,
    // PlanSwiper,
    Preloader,
    SmallBlock
  },
  data () {
    return {
      date: null,
      date_for_select: null,
      data: null,
      errors: null,
      blogs: null,
      recipes: null,
      statistics: null,
      diets: null,
      week_plan: null,
      today: true,
      week: false
    }
  },
  computed: {
    dish_changed() {
      return this.$store.getters.dish_status()
    }
  },
  mounted() {
    this.currentDay()
    this.getDayPlan()
    this.getBlogs()
    // this.getRecipes() 
    this.getCurrentDayStatistics()
    // this.getDiets()
  },
  watch: {
    'date'() {
      this.getDayPlan()
    },
    'dish_changed'() {
      this.getDayPlan()
    },
  },
  methods: {
    getCurrentDayStatistics() {
      Statistics.getCurrentDayData(data => this.statistics = data, errors => this.errors = errors  )
    },
    getWeekPlan(){
      this.week = true
      this.today = false
      DailyRation.generateWeekPlan(data => this.week_plan = data, errors => this.errors = errors  )
    },
    getDayPlan() {
      this.today = true
      this.week = false
      let date_to_params = moment(this.date_for_select).format('DD-MM-YYYY')
      let params = {
        date: date_to_params
      }
      // console.log(this.date_for_select)
      DailyRation.generateDayPlan(data => this.data = data, errors => this.errors = errors, params )
    },
    getDayPlanGenerate(){
      this.data = null
      let date_to_params = moment(this.date_for_select).format('DD-MM-YYYY')
      let params = {
        date: date_to_params,
        new: 1
      }
      // console.log(this.date_for_select)
      DailyRation.generateDayPlan(data => this.data = data, errors => this.errors = errors, params )
    },
    getBlogs(){
      Blog.list(data => this.blogs = data, errors => this.errors = errors)
    },
    // getRecipes(){
    //   Recipe.list(data => this.recipes = data, errors => this.errors = errors)
    // },
    // getDiets(){
    //   Diet.list(data => this.diets = data, errors => this.errors = errors)
    // },
    goToNextPage() {
      this.$router.push({ name: 'sign-in' })
    },
    currentDay () {
      let currentDate = new Date();
      this.formatDate(currentDate)
    },
    nextDay() {
      let current_day = new Date(this.date_for_select);
      let nextDay = new Date(current_day);
      nextDay.setDate(current_day.getDate() + 1);
      this.formatDate(nextDay)
    },
    previousDay() {
      let current_day = new Date(this.date_for_select);
      let previousDay = new Date(current_day);
      previousDay.setDate(current_day.getDate() - 1);
      this.formatDate(previousDay)
    },
    formatDate(date){
      let formatted = date.toLocaleString('ru-RU', { day: '2-digit', month: 'long', year: 'numeric' })
      this.date = formatted
      this.date_for_select = date
    },
  }
}
</script>