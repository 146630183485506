<template>
  <div class="main single" v-if="diet">
    <div class="single__cover">
      <img :src="diet.image" alt="" class="single__cover__img">
      <div class="abs-full-block transparent-blue single__cover__blog">
      </div>
    </div>
    <div class="single__body">
      <div class="container-ul">
        <div class="grey_line mb-3"></div>

        <p class="single__title">{{diet.name}}</p>
        <p class="single__content">{{diet.description}}</p>

        <p class="diet-plan__price"> Цена: <span> ₸  {{ diet.price }} </span> </p>

        <button class="base-btn base-btn--gradient">Купить</button>

      </div>
    </div>

  </div>
</template>
<script>
import Diet from "@/api/Diet";
export default {
  components: {
  },
  data() {
    return {
      diet: null,
      errors: null
    }
  },
  mounted() {
    this.getDiet()
  },
  methods: {
    getDiet(){
      Diet.show(data => this.diet = data, errors => this.errors = errors,  this.$route.params.id)
    }
  }
}
</script>