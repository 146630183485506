<template>
  <div class="sign-in abs-full-block">
    <div class="sign-in__flex">
      <div class="sign-in__flex-center">
        <div class="welcome__logo"><img src="../../../assets/images/nomadiet-logo.png" alt=""></div>
        <h1 class="sign-in__title">NutriSteppe</h1>
      </div>
      <div class="w-100">
        <label> Email</label>
        <input class="base-input" type="text" placeholder="" v-model="user.email">
        <label> Password</label>
        <input class="base-input" type="password"  placeholder=" " v-model="user.password">

        <button class="base-btn base-btn--gradient" @click="login">Войти</button>
        <router-link :to="{name: 'forgot-password'}" class="forgot-password mb-3">Забыли пароль?</router-link>
      </div>
      <div class="w-100">
        <button class="base-btn base-btn--violet"  @click="goToRegistration">Регистрация</button>
      </div>
    </div>
  </div>
</template>

<script>
import Auth from "@/api/Auth";
export default {
  data() {
    return {
      data: null,
      errors: null,
      user: {
        email: null,
        password: null
      }
    }
  },
  watch: {
    'errors' () {
      if (this.errors) {
        this.$store.dispatch('SHOW_ALERT', [this.errors.message])
      }
    },
    data () {
      this.$store.dispatch('SET_USER', this.data)
    },
  },
  mounted() {
    this.checkAuth()
  },
  methods: {
    checkAuth() {
      if(localStorage.token){
        this.$router.push({ name: 'main' })
      }
    },
    login() {
      Auth.login(data => {this.data = data}, errors => { this.errors = errors },
          {
            email: this.user.email,
            password: this.user.password
          })
          .then(()=> {
            if (this.data.success === true) {
              this.$router.push({ name: 'main' })
            }
          })
    },
    goToRegistration() {
      this.$router.push({ name: 'registration-step-1' })
    }
  }
}
</script>