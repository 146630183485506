<template>
  <div class="block-small mb-3" >
    <transition name="animate-ul" enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut" >
      <div class="block-large__abs" v-if="show">
        <div class="block-large__abs__body">
          <button class="block-large__abs__close" @click="toggleInfo"><img src="../../assets/images/close_tag.svg" alt="" ></button>

          <table class="w-100 border-0">
            <tr
                v-for="(item, index) in dish.lights"
                :key="index+'light_info'"
            >
              <td>
              <span class="mr-1">
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="5" cy="5" r="5" :fill="item.colorHex"/>
                </svg>
              </span>
                {{item.name}}
              </td>
              <td>{{item.weight}} {{item.unit}}</td>
            </tr>
          </table>
        </div>
      </div>
    </transition>
    <img :src="dish.photo" alt="" class="block-small__cover">
    <div class="block-small__body">
      <div  @click="toggleInfo">
                <span
                    class="mr-1"
                    v-for="(item_light, index_light) in dish.lights"
                    :key="'light'+index_light"
                >
                  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="5" cy="5" r="5" :fill="item_light.colorHex"/>
                  </svg>
                </span>
      </div>
      <p class="block-small__title mb-1 mt-2">{{ dish.name }}</p>

      <div class="d-flex">
        <p class="mr-3 block-large__info"><img src="../../assets/images/food.svg" alt=""> {{ dish.kilocalories }} кал</p>
        <p class="block-large__info"><img src="../../assets/images/weight.svg" alt=""> {{ dish.weight }} грамм</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['dish'],
  data() {
    return {
      show: false
    }
  },
  methods: {
    toggleInfo() {
      this.show = !this.show
    },
  }
}
</script>