<template>
  <div class="main single" v-if="data">
     
    <div class="single__cover">
      <img :src="data.image" alt="" class="single__cover__img">
<!--      <div class="abs-full-block gradient-bg-violet"> </div>-->
    </div> 
    <div class="single__body">
      <div class="container-ul">
        <div class="grey_line"></div>
        <h1 class="single__title">
          <div class="d-inline"   data-toggle="modal" data-target="#exampleModalCenter">
            <span
                class="mr-1"
                v-for="(item, index) in data.lights"
                :key="'light'+index"
            >
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="5" cy="5" r="5" :fill="item.colorHex"/>
            </svg>
          </span>
          </div>
          {{ data.name }}
        </h1>

        <p class="single__info"  v-if="data.category.length">Категория: <span class="mr-1" v-for="(item, index) in data.category" :key="index+'dish_category'">{{item.name}}.</span></p>
        <p class="single__info">Масса: {{ data.weight }} г</p>
        <p class="single__info">Количество порций: {{data.servings}}</p>

<!--        <div class="my-2" v-if="data.types.length">-->
<!--          <div class="base-tag"  v-for="(item, index) in data.types" :key="index+'dish_type'">{{ item.name }}</div>-->
<!--        </div>-->

        <p class="single__subtitle mb-1 mt-4">Описание</p>
        <p class="single__content"> {{ data.description }} </p>

        <template v-if="data.macronutrients ">
          <p class="single__subtitle mt-4">Макронутриенты на 100 гр.</p>
          <div class="single__micronutrient">
            <div class="single__micronutrient__block">
              <p class="single__micronutrient__amount">{{data.macronutrients.kilocalories}}</p>
              <p class="single__micronutrient__text">калорий</p>
            </div>
            <div class="single__micronutrient__block">
              <p class="single__micronutrient__amount">{{ data.macronutrients.protein }} гр</p>
              <p class="single__micronutrient__text">белки</p>
            </div>
            <div class="single__micronutrient__block">
              <p class="single__micronutrient__amount">{{ data.macronutrients.fat }} гр</p>
              <p class="single__micronutrient__text">жиры</p>
            </div>
            <div class="single__micronutrient__block">
              <p class="single__micronutrient__amount">{{ data.macronutrients.carbohydrate }} гр</p>
              <p class="single__micronutrient__text">углеводы</p>
            </div>
          </div>
        </template>

<!--        <p class="single__subtitle mt-4">Макронутриенты</p>-->
<!--        <div>-->
<!--          <BarChart :data="chart_data" :labels="chart_labels" class="bar_chart_for_combination" id="myChart" />-->
<!--        </div>-->
        <template v-if="data.ingredients.length >= 1">
          <p class="single__subtitle mt-4">Ингредиенты</p>
          <div class="info-board__block">
            <table>
              <tr>
                <th>Название</th>
                <th>Количество</th>
                <th>Единицы</th>
              </tr>
              <tr v-for="(item, index) in data.ingredients" :key="index+'ingredients'">
                <td>{{ item.name }}</td>
                <td>{{ item.pivot.weight }}</td>
                <td>{{ item.pivot.unit_type_name }}</td>
              </tr>
            </table>
          </div>
        </template>

        <template v-if="data.recipe_description.length > 1 ">
          <p class="single__subtitle mt-4 mb-1">Рецепт</p>
          <div v-for="(item, index) in data.recipe_description_arr" :key="index + 'recipe'">
            <p class="single__step-title">ШАГ {{ index+1 }}</p>
            <p class="single__step-description">{{item.text}}</p>
          </div>
          <p class="single__step-description text-bold mb-1"  v-if="data.author_name">Автор: {{data.author_name}}</p>
          <p class="single__step-description text-bold" v-if="data.recipe_link">Ссылка на рецепт: <a :href="data.recipe_link" target="_blank">{{data.recipe_link}}</a></p>
        </template>

<!--        Comments-->

        <template>
          <button  @click="getComments" class="base-btn btn-light shadow-sm base-text" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
            <span v-if="!comment_block">Комментарии </span> <span v-if="comment_block">Cкрыть комментарии </span>
          </button>
          <div class="collapse" id="collapseExample">
            <template v-if="comments">
              <div class="py-3">
                <div class="card border-light shadow-sm py-2 px-3 mb-3" v-for="(item, index) in comments" :key="'comment'+index">
                  <div class="base-flex-justify">
                    <p class="single__step-description text-bold mb-1"> {{item.user_name}} </p>
                    <p class="single__step-description text-bold mb-1">{{ item.created_at | moment }} </p>
                  </div>
                  <p class="mb-0 base-text">{{item.comment_text}}</p>
                </div>
                <form  @submit.prevent="sendComment">
                  <textarea v-model="comment" class="form-control single__step-description border border-light shadow-sm" rows="3" placeholder="Написать комментарий" required></textarea>
                  <p v-if="comment_error" class="text-bold single__step-description text-danger mb-1 mt-2">{{comment_error}}</p>
                  <button type="submit" class="btn btn-light single__step-description mt-2 border">Отправить</button>
                </form>
              </div>
            </template>
            <Preloader v-else></Preloader>
          </div>
        </template>


        <!-- <template v-if="data.micronutrients.length >= 1">
          <p class="single__subtitle mt-4">Микронутриенты на 100 гр.    </p>
          <div class="table-responsive">
            <div class="info-board__block">
              <table>
                <tr>
                  <th>Название</th>
                  <th>Количество</th>
                </tr>
                <tr v-for="(item, index) in data.micronutrients" :key="index+'micronutrient'">
                  <td>{{ item.name }}</td>
                  <td>{{ item.pivot.weight }}</td>
                </tr>
              </table>
            </div>
          </div>
        </template> -->

      </div>
    </div>

<!--     Modal for components of meal-->
    <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="base-flex">
<!--              <h5 class="modal-title base-title" id="exampleModalLongTitle">Total factor: 0.4213</h5>-->
              <h5></h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div>
              <table class="single__modal-table">
                <tr v-for="(item, index) in data.lights" :key="index+'factory'">
                  <td><span class="green-mark" :style="{'background-color': item.colorHex + '!important' }"></span> {{ item.name }} </td>
                  <td>{{ item.weight }} г</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import moment from "moment";
import Recipe from "@/api/Recipe";
// import BarChart from '../../partials/Charts/BarChart'
import Preloader from "@/components/static/Preloader";
import _ from "underscore";
export default {
  components: {
    // BarChart,
    Preloader
  },
  data() {
    return {
      data: null,
      errors: null,
      comment_block: false,
      comments: null,
      comment: null,
      comment_send_data: null,
      comment_error: null,
    }
  },
  mounted() {
    this.getDish()
  },
  watch: {
    comment() {
      if (this.comment.length > 249) {
        this.comment_error = "Текст не может быть длиннее 250 символов."
      } else {
        this.comment_error = null
      }
    }
  },
  computed: {
    chart_labels() {
      if (this.data){
        let labels = _.keys(this.data.macronutrients)
        return labels
      } else {
        return []
      }
    },
    chart_data() {
      if (this.data){
        let data =  _.values(this.data.macronutrients)
        return data
      } else  {
        return []
      }
    }
  },
  filters: {
    moment(date) {
      return moment(new Date(date)).format('MMMM DD, YYYY') ;
    }
  },
  methods:{
    getDish(){
      Recipe.show(data => this.data = data, errors => this.errors = errors,  this.$route.params.id)
    },
    getComments() {
      this.comment_block = !this.comment_block

      Recipe.getComments(data => this.comments = data, errors => this.errors = errors,  this.data.id)
    },
    sendComment(){
      if (this.comment_error === null) {
        Recipe.sendComment(data => {this.comment_send_data = data}, errors => { this.errors = errors },
            {
              text: this.comment,
              dish_id: this.data.id
            })
            .then(()=> {
              this.comment = null
              this.$store.dispatch('SHOW_ALERT', ["Ваш комментарий будет скоро добавлен"])
            })
      }
    }
  }
}
</script>