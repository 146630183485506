<template>
  <div class="goal-step abs-full-block">

    <div class="sign-in__flex">
      <div class="w-100">
        <p class="goal-step__title">Мои параметры</p>


        <label class="base-label">Вес (в кг)</label>
        <input class="base-input" type="number"  placeholder=" " v-model="user.weight">

        <label class="base-label">Рост</label>
        <input class="base-input" type="number"  placeholder=" " v-model="user.height">

        <div class="w-100">
          <button  @click="updateData" class="base-btn base-btn--violet mt-4">Сохранить</button>
          <router-link :to="{name: 'profile'}" class="forgot-password mb-3">Отменить</router-link>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import Profile from "@/api/Profile";

export default {
  data() {
    return {
      user: {
        weight: null,
        height:null,
      },
      data: null,
      errors: null
    }
  },
  methods: {
    updateData() {
      let params = {
        weight: this.user.weight,
        height: this.user.height,
      }
      Profile.editParameters(data => this.data = data, errors => this.errors = errors, params)
          .then(() => {
            this.$store.dispatch('SHOW_ALERT', [this.data.message])
            setTimeout(() => {
              this.$router.push({ name: 'profile' })
            }, 0)
          })
    },
  }
}
</script>