<template>
  <div class="container-ul advance">
    <div class="main__top">
      <button class="main__top__arrow" @click="previousDay"><img src="./../../../assets/images/left.svg" alt=""></button>
      <p class="main__top__title">{{ date }}</p>
      <button class="main__top__arrow" @click="nextDay"><img src="./../../../assets/images/right.svg" alt=""></button>
    </div>

    <p class="advance__title"><span class="amount">1.5 / <span class="highlight-water">3.5</span></span>  литра</p>

    <ChartWater :data_bar="water_data_bar" class="water-bar-chart-resize"></ChartWater>

    <p class="advance__subtitle pt-4">Анализ</p>

    <div class="statistics__flex bg-water">
      <div class="statistics__flex">
        <div>
              <span v-for="(n, index) in water_progress.drunk" :key="index+'drunk'">
                <img src="../../../assets/images/water-drunk.svg" alt="">
              </span>
          <span v-for="(n, index) in water_progress.have_to" :key="index+'plus'">
                <img src="../../../assets/images/plus-water.svg" alt="" @click="addWater">
              </span>
        </div>
      </div>
    </div>


  </div>
</template>
<script>
import ChartWater from '../../partials/Charts/WaterBarChart'
export default {
  components: {
    ChartWater
  },
  data () {
    return {
      date: null,
      date_for_select: null,
      water_data_bar: {
        data: [2, 1.5, 1, 2, 2.5, 3, 2, 1],
        label: 'В составе: ',
        labels: [ 'Mon', 'Tue', 'Wen', 'Thu', 'Fri', 'Sat', 'Sun'],
      },
      water_progress:{
        drunk: 5,
        have_to: 4
      },

    }
  },
  mounted() {
    this.currentDay()
  },
  methods: {
    addWater(){
      this.water_progress.drunk ++
      this.water_progress.have_to --
    },
    goToNextPage() {
      this.$router.push({ name: 'sign-in' })
    },
    currentDay () {
      let currentDate = new Date();
      this.formatDate(currentDate)
    },
    nextDay() {
      let current_day = new Date(this.date_for_select);
      let nextDay = new Date(current_day);
      nextDay.setDate(current_day.getDate() + 1);
      this.formatDate(nextDay)
    },
    previousDay() {
      let current_day = new Date(this.date_for_select);
      let previousDay = new Date(current_day);
      previousDay.setDate(current_day.getDate() - 1);
      this.formatDate(previousDay)
    },
    formatDate(date){
      let formatted = date.toLocaleString('ru-RU', { day: '2-digit', month: 'long', year: 'numeric' })
      this.date = formatted
      this.date_for_select = date
    },
  }
}
</script>