import {HTTP} from '@/http'

class Favourite {

    static add(then, catcher, params = null) {
        return HTTP.post('/set-favorite-dish', params)
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }

}
export default Favourite