import {HTTP} from '@/http'

class Shop {

    static listByIngredients(then, catcher, params = null) {
        return HTTP.get('/shop-list-by-ingredients', {params})
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }
    static listByDishes(then, catcher, params = null) {
        return HTTP.get('/shop-list-by-dishes', {params})
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }
}
export default Shop