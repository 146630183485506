<template>
  <div>
    <ul class="menu">
      <li>
        <router-link :to="{ name: 'main' }" class="menu__item">
          <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="#6D6D6D" d="M18.8957 11.2717C18.5259 11.2717 18.2262 11.5715 18.2262 11.9413V18.8213H14.5325V11.9413C14.5325 11.5715 14.2328 11.2717 13.863 11.2717H8.82966C8.45979 11.2717 8.16009 11.5715 8.16009 11.9413V18.8213H4.46611V11.9413C4.46611 11.5715 4.16641 11.2717 3.79654 11.2717C3.42667 11.2717 3.12697 11.5715 3.12697 11.9413V19.4908C3.12697 19.8606 3.42667 20.1604 3.79654 20.1604H8.82966H13.863H18.8957C19.2656 20.1604 19.5653 19.8606 19.5653 19.4908V11.9413C19.5653 11.5715 19.2655 11.2717 18.8957 11.2717ZM9.49923 18.8213V12.6109H13.1934V18.8213H9.49923Z"/>
            <path fill="#6D6D6D" d="M22.4962 11.4678L11.8192 0.791604C11.5578 0.530114 11.1338 0.530203 10.8722 0.791604L0.196117 11.4678C-0.0653725 11.7293 -0.0653725 12.1532 0.196117 12.4146C0.457696 12.6761 0.88158 12.6761 1.14307 12.4146L11.3457 2.21199L21.5494 12.4147C21.6801 12.5454 21.8515 12.6108 22.0228 12.6108C22.1941 12.6108 22.3655 12.5454 22.4963 12.4146C22.7577 12.1532 22.7577 11.7293 22.4962 11.4678Z"/>
          </svg>

          <p>План питания</p>
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: 'shopping-list' }" class="menu__item">
          <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.94 11.4067H12.8913C12.057 11.4067 11.3785 12.0852 11.3785 12.9196V15.2576C11.3785 16.092 12.057 16.7705 12.8913 16.7705H15.94C16.7743 16.7705 17.4528 16.092 17.4528 15.2576V12.9196C17.4528 12.0852 16.7743 11.4067 15.94 11.4067ZM16.3526 15.2576C16.3526 15.4869 16.1646 15.6702 15.94 15.6702H12.8913C12.6621 15.6702 12.4787 15.4823 12.4787 15.2576V12.9196C12.4787 12.6904 12.6667 12.507 12.8913 12.507H15.94C16.1692 12.507 16.3526 12.695 16.3526 12.9196V15.2576Z" fill="#6D6D6D"/>
            <path d="M22.436 7.15699C22.436 7.06072 22.4131 6.96903 22.3627 6.88651L19.0253 1.01848C18.929 0.848856 18.7456 0.738831 18.5485 0.738831H3.88298C3.68586 0.738831 3.50248 0.844272 3.40621 1.01848L0.0733504 6.88651C0.0275064 6.96903 0 7.06072 0 7.15699C0 8.47271 0.793101 9.60047 1.92545 10.1002V19.7778C1.92545 20.0804 2.173 20.328 2.47557 20.328H5.51503C5.51962 20.328 5.5242 20.328 5.52878 20.328H9.79227C9.79686 20.328 9.80144 20.328 9.80603 20.328H19.9559C20.2585 20.328 20.506 20.0804 20.506 19.7778V10.1689C20.506 10.146 20.506 10.1277 20.5014 10.1093C21.6384 9.60964 22.436 8.47729 22.436 7.15699ZM4.20389 1.84367H18.2276L20.8361 6.43265H1.59995L4.20389 1.84367ZM15.9675 7.52832C15.7933 8.51855 14.9268 9.27039 13.8861 9.27039C12.8455 9.27039 11.979 8.51855 11.8048 7.52832H15.9675ZM10.6358 7.52832C10.4616 8.51855 9.59515 9.27039 8.55449 9.27039C7.51383 9.27039 6.64738 8.51855 6.46859 7.52832H10.6358ZM1.13693 7.52832H5.30415C5.12994 8.51855 4.25891 9.27039 3.21825 9.27039C2.17759 9.27498 1.31114 8.51855 1.13693 7.52832ZM9.24215 19.2277H6.07891V13.2909C6.07891 12.86 6.42733 12.507 6.86284 12.507H8.4628C8.89373 12.507 9.24673 12.8554 9.24673 13.2909V19.2277H9.24215ZM19.4058 19.2277H10.3424V13.2909C10.3424 12.2548 9.49887 11.4067 8.45821 11.4067H6.85826C5.82219 11.4067 4.97407 12.2503 4.97407 13.2909V19.2323H3.0257V10.3707C3.08988 10.3752 3.15407 10.3752 3.21825 10.3752C4.32767 10.3752 5.30873 9.81135 5.88637 8.95407C6.464 9.81135 7.44506 10.3752 8.55449 10.3752C9.66391 10.3752 10.6404 9.81135 11.2226 8.95407C11.8002 9.81135 12.7813 10.3752 13.8861 10.3752C14.9956 10.3752 15.972 9.81135 16.5497 8.95407C17.1273 9.81135 18.1084 10.3752 19.2178 10.3752C19.282 10.3752 19.3416 10.3707 19.4058 10.3707V19.2277ZM19.2178 9.27498C18.1771 9.27498 17.3107 8.52314 17.1365 7.53291H21.3037C21.1249 8.51855 20.2585 9.27498 19.2178 9.27498Z" fill="#6D6D6D"/>
          </svg>
          <p> Список покупок </p>
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: 'recipes' }" class="menu__item menu__item__recipe">
          <svg width="28" height="25" viewBox="0 0 28 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="path-1-inside-1" fill="white">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M18.7274 14.572C17.5934 16.904 15.7126 18.4291 13.5842 18.4291C12.1246 18.4291 10.7814 17.7119 9.71573 16.5092C8.72734 17.1823 7.54412 17.574 6.27253 17.574C2.80831 17.574 0 14.6671 0 11.0814C0 7.49558 2.80831 4.58874 6.27253 4.58874C6.928 4.58874 7.55999 4.69281 8.15392 4.88585C9.23924 2.19299 11.2647 0.381836 13.5842 0.381836C15.5026 0.381836 17.2199 1.62082 18.3704 3.57292C19.3411 2.94594 20.4926 2.58286 21.7275 2.58286C25.1917 2.58286 28 5.44043 28 8.96543C28 12.4904 25.1917 15.348 21.7275 15.348C20.6408 15.348 19.6186 15.0668 18.7274 14.572Z"/>
            </mask>
            <path d="M18.7274 14.572L19.2128 13.6977L18.2898 13.1853L17.8281 14.1347L18.7274 14.572ZM9.71573 16.5092L10.4642 15.846L9.8804 15.1871L9.15283 15.6827L9.71573 16.5092ZM8.15392 4.88585L7.8448 5.83688L8.7325 6.12541L9.08142 5.25967L8.15392 4.88585ZM18.3704 3.57292L17.5089 4.08069L18.0379 4.97816L18.913 4.41292L18.3704 3.57292ZM13.5842 19.4291C16.2328 19.4291 18.3953 17.5416 19.6267 15.0093L17.8281 14.1347C16.7915 16.2664 15.1923 17.4291 13.5842 17.4291V19.4291ZM8.96727 17.1724C10.1764 18.5369 11.7752 19.4291 13.5842 19.4291V17.4291C12.474 17.4291 11.3865 16.8869 10.4642 15.846L8.96727 17.1724ZM6.27253 18.574C7.75326 18.574 9.13131 18.1171 10.2786 17.3357L9.15283 15.6827C8.32336 16.2476 7.33497 16.574 6.27253 16.574V18.574ZM-1 11.0814C-1 15.1866 2.22379 18.574 6.27253 18.574V16.574C3.39282 16.574 1 14.1476 1 11.0814H-1ZM6.27253 3.58874C2.22379 3.58874 -1 6.97608 -1 11.0814H1C1 8.01508 3.39282 5.58874 6.27253 5.58874V3.58874ZM8.46303 3.93483C7.77076 3.70982 7.03452 3.58874 6.27253 3.58874V5.58874C6.82148 5.58874 7.34922 5.6758 7.8448 5.83688L8.46303 3.93483ZM13.5842 -0.618164C10.6959 -0.618164 8.39357 1.61611 7.22641 4.51204L9.08142 5.25967C10.0849 2.76986 11.8334 1.38184 13.5842 1.38184V-0.618164ZM19.2319 3.06515C17.9673 0.919479 15.9693 -0.618164 13.5842 -0.618164V1.38184C15.0359 1.38184 16.4725 2.32217 17.5089 4.08069L19.2319 3.06515ZM18.913 4.41292C19.7277 3.88672 20.6918 3.58286 21.7275 3.58286V1.58286C20.2935 1.58286 18.9545 2.00517 17.8279 2.73291L18.913 4.41292ZM21.7275 3.58286C24.6231 3.58286 27 5.97625 27 8.96543H29C29 4.90462 25.7603 1.58286 21.7275 1.58286V3.58286ZM27 8.96543C27 11.9546 24.6231 14.348 21.7275 14.348V16.348C25.7603 16.348 29 13.0262 29 8.96543H27ZM21.7275 14.348C20.8155 14.348 19.9599 14.1125 19.2128 13.6977L18.242 15.4463C19.2773 16.0211 20.466 16.348 21.7275 16.348V14.348Z" fill="#6D6D6D" mask="url(#path-1-inside-1)"/>
            <path d="M9.0284 15.6965C7.45353 14.9332 6.02373 11.043 5.72633 10.5636C4.24595 8.17699 2.77458 9.30994 4.32025 11.7372C5.61064 13.7636 9.0284 15.6965 9.0284 15.6965Z" stroke="#6D6D6D"/>
            <path d="M18.7562 13.413C20.383 12.7677 22.0954 8.99337 22.4273 8.53712C24.0794 6.26607 25.4633 7.50429 23.7431 9.81112C22.307 11.737 18.7562 13.413 18.7562 13.413Z" stroke="#6D6D6D"/>
            <path d="M13.5223 8.53448C16.1631 9.63045 12.8268 15.1636 12.5938 15.7391C17.1919 11.7256 16.9973 3.26808 13.6837 6.97121C13.3792 7.3115 13.0516 8.33914 13.5223 8.53448Z" stroke="#6D6D6D"/>
            <path d="M6.71118 23.8399V16.011C9.70646 15.8869 12.268 15.0869 14.4194 14.415C14.9717 14.2426 15.4969 14.0785 15.9955 13.9365C17.2891 13.5681 18.3779 13.3531 19.3375 13.4506C20.2193 13.5403 21.0191 13.8971 21.7777 14.7245V21.7009C21.0694 21.0522 20.3106 20.6724 19.484 20.5142C18.3209 20.2916 17.0908 20.5222 15.7932 20.9234C14.9843 21.1735 14.1054 21.5054 13.1739 21.8571C12.6246 22.0645 12.0571 22.2789 11.4749 22.4874C10.0402 23.0011 8.4657 23.5001 6.71118 23.8399Z" fill="white" stroke="#6D6D6D"/>
          </svg>


          <p>Рецепты</p>
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: 'plan' }" class="menu__item">
          <svg width="26" height="21" viewBox="0 0 26 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.6247 20.2715C15.4605 20.2715 15.3005 20.2065 15.183 20.0882C15.0388 19.944 14.9738 19.7382 15.0097 19.5382L15.4513 17.034C15.4738 16.9074 15.5347 16.7907 15.6247 16.7007L21.8122 10.5132C22.0755 10.2499 22.3547 10.0849 22.6422 10.0257C23.0388 9.94237 23.4247 10.0632 23.7272 10.3657L24.758 11.3965C25.3263 11.9657 25.3263 12.8899 24.758 13.459L18.5713 19.6465C18.4805 19.7365 18.3638 19.7982 18.238 19.8199L15.7338 20.2615C15.6972 20.2682 15.6613 20.2715 15.6247 20.2715ZM18.1297 19.2049H18.138H18.1297ZM16.648 17.4449L16.3955 18.8757L17.8263 18.6232L23.8738 12.5749C23.9547 12.4932 23.9547 12.3615 23.8738 12.2799L22.8505 11.2565C22.8422 11.2724 22.7797 11.3115 22.6955 11.3957L16.648 17.4449Z" fill="#6D6D6D"/>
            <path d="M10.01 17H2.75C1.233 17 0 16.0266 0 14.8289V4.17105C0 2.97342 1.233 2 2.75 2H14.25C15.767 2 17 2.97342 17 4.17105V10.5342C17 10.8611 16.664 11.1263 16.25 11.1263C15.836 11.1263 15.5 10.8611 15.5 10.5342V4.17105C15.5 3.62711 14.939 3.18421 14.25 3.18421H2.75C2.061 3.18421 1.5 3.62711 1.5 4.17105V14.8289C1.5 15.3729 2.061 15.8158 2.75 15.8158H10.01C10.424 15.8158 10.76 16.0811 10.76 16.4079C10.76 16.7347 10.424 17 10.01 17Z" fill="#6D6D6D"/>
            <path d="M3.125 4.16667C2.78 4.16667 2.5 3.88667 2.5 3.54167V0.625C2.5 0.28 2.78 0 3.125 0C3.47 0 3.75 0.28 3.75 0.625V3.54167C3.75 3.88667 3.47 4.16667 3.125 4.16667Z" fill="#6D6D6D"/>
            <path d="M7.08334 4.16667C6.73834 4.16667 6.45834 3.88667 6.45834 3.54167V0.625C6.45834 0.28 6.73834 0 7.08334 0C7.42834 0 7.70834 0.28 7.70834 0.625V3.54167C7.70834 3.88667 7.42834 4.16667 7.08334 4.16667Z" fill="#6D6D6D"/>
            <path d="M11.0417 4.16667C10.6967 4.16667 10.4167 3.88667 10.4167 3.54167V0.625C10.4167 0.28 10.6967 0 11.0417 0C11.3867 0 11.6667 0.28 11.6667 0.625V3.54167C11.6667 3.88667 11.3867 4.16667 11.0417 4.16667Z" fill="#6D6D6D"/>
            <path d="M11.875 11.25H5.625C5.28 11.25 5 10.97 5 10.625C5 10.28 5.28 10 5.625 10H11.875C12.22 10 12.5 10.28 12.5 10.625C12.5 10.97 12.22 11.25 11.875 11.25Z" fill="#6D6D6D"/>
            <path d="M9.375 7.91666H5.625C5.28 7.91666 5 7.63666 5 7.29166C5 6.94666 5.28 6.66666 5.625 6.66666H9.375C9.72 6.66666 10 6.94666 10 7.29166C10 7.63666 9.72 7.91666 9.375 7.91666Z" fill="#6D6D6D"/>
          </svg>

          <p>Прогресс</p>
        </router-link>
      </li>
<!--      <li>-->
<!--        <router-link :to="{ name: 'profile' }" class="menu__item">-->
<!--          <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--            <g clip-path="url(#clip0)">-->
<!--              <path d="M9.49999 0.553223C6.93429 0.553223 4.84692 2.64059 4.84692 5.20629C4.84692 7.772 6.93429 9.85933 9.49999 9.85933C12.0657 9.85933 14.1531 7.77196 14.1531 5.20626C14.1531 2.64055 12.0657 0.553223 9.49999 0.553223ZM9.49999 8.6961C7.57572 8.6961 6.01019 7.13056 6.01019 5.20629C6.01019 3.28202 7.57572 1.71649 9.49999 1.71649C11.4243 1.71649 12.9898 3.28202 12.9898 5.20629C12.9898 7.13056 11.4243 8.6961 9.49999 8.6961Z" fill="#6D6D6D"/>-->
<!--              <path d="M9.5 11.0226C5.11694 11.0226 1.55103 14.5885 1.55103 18.9716V19.5532H17.449V18.9716C17.449 14.5885 13.8831 11.0226 9.5 11.0226ZM2.73897 18.3899C3.03499 14.9196 5.95427 12.1859 9.5 12.1859C13.0457 12.1859 15.965 14.9196 16.261 18.3899H2.73897Z" fill="#6D6D6D"/>-->
<!--            </g>-->
<!--            <defs>-->
<!--              <clipPath id="clip0">-->
<!--                <rect width="19" height="19" fill="white" transform="translate(0 0.553223)"/>-->
<!--              </clipPath>-->
<!--            </defs>-->
<!--          </svg>-->

<!--          <p>Профиль</p>-->
<!--        </router-link>-->
<!--      </li>-->
    </ul>
  </div>
</template>
<script>
import Profile from "@/api/Profile";
export default {
  data() {
    return {
      data: null,
      errors: null,
    }
  },
  mounted() {
    this.getName()
  },
  methods: {
    getName() {
      Profile.me(data=> this.data = data, errors=> this.errors = errors)
      .then(()=> {
        // console.log(this.data)
      })
    }
  }
}
</script>