import {HTTP} from '@/http'

class Statistics {

    static addWater(then, catcher, params = null) {
        return HTTP.post('/user-water', params)
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }
    static getCurrentDayData(then, catcher, params = null) {
        return HTTP.get('/today-stat', {params})
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }
    static show(then, catcher, id,  params = null) {
        return HTTP.get('/articles/'+ id, params)
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }

}
export default Statistics
