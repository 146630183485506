import { Bar } from 'vue-chartjs'


export default {
    extends: Bar,
    props: ['data_bar'],
    mounted () {
        this.renderChart({
            labels: this.data_bar.labels,

            datasets: [
                {
                    data: this.data_bar.data,
                    label: this.data_bar.label,
                    backgroundColor: '#00F0FF',

                }
            ]
        }, {
            // curvature: 5,
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false,
            },
            scales: {
                yAxes: [{
                    stacked: false,
                    ticks: {
                        min: 0,
                        display: false
                    },
                    gridLines: {
                        display: false
                    }
                }],
                xAxes: [{
                    stacked: true,
                    gridLines: {
                        display: false,
                        min: 0
                    },
                    ticks: {
                        fontColor: "#949BA3",
                    },
                }],
            },
        })
    }
}

