<template>
  <div class="statistics">
    <p class="base-title mb-2">Статистика</p>

    <div class="w-100"  v-if="data">
      <Slick
          ref="slick"
          :options="statistics_swiper_options" >
        <router-link :to="{name: 'plan'}" class="swiper-link">
          <div class="statistics__block gradient-slider-1 statistics__flex">

            <div>
              <p class="statistics__type">Калорий</p>
              <p class="statistics__text">суточная норма на день</p>
              <div class="statistics__flex">
                <div>
                  <p class="statistics__number"><span class="text-green">{{ data.calory.left_cal }}</span> ккал</p>
                  <p class="statistics__text">осталось</p>
                </div>
                <div>
                  <p class="statistics__number"> <span class="text-pink">{{ data.calory.recommended_total_calory }}</span> ккал</p>
                  <p class="statistics__text">рекомендовано</p>
                </div>
              </div>
            </div>

            <div>
              <div class="progress-ul">
                <svg>
                  <circle  cx="60" cy="60" r="60"></circle>
                  <circle  cx="60" cy="60" r="60" :style="{'stroke-dashoffset': 380 - (380 * calories_percentage) / 100 }"></circle>
                </svg>
                <div class="progress-ul__info">
                  <p class="progress-ul__number">{{ data.calory.current_cal }}</p>
                  <p class="progress-ul__name">калорий</p>
                  <div class="progress-ul__abs"></div>
                </div>
              </div>
            </div>


          </div>
        </router-link>
        <router-link :to="{name: 'plan'}" class="swiper-link">
          <div class="statistics__block gradient-slider-2">
            <p class="statistics__type">Вода</p>
            <div class="statistics__flex">
              <div>
                <Water03 color="#00F0FF" v-for="(item, index) in data.water.current_water_glasses " :key="index+'water_drunk'" />
                <Water03 color="#B8B8B8" v-for="(item, index) in data.water.recommended_water_glasses " :key="index+'water_to_drink'" />
              </div>
              <div>
                <div>
                  <p class="statistics__number"> <span class="text-pink">{{ data.water.recommended_water }}</span> литра</p>
                  <p class="statistics__text mb-0">рекомендовано</p>
                </div>
                <div>
                  <p class="statistics__number"> <span class="text-blue">{{ data.water.recommended_water - data.water.current_water }}</span> литра</p>
                  <p class="statistics__text mb-0">осталось</p>
                </div>
              </div>
            </div>
          </div>
        </router-link>
<!--        <router-link :to="{name: 'plan'}" class="swiper-link">-->
<!--          <div class="statistics__block gradient-slider-3 statistics__flex">-->

<!--            <div class="statistics__flex-column">-->
<!--              <p class="statistics__type w-100">Вес за период</p>-->
<!--              <line-chart  :weight_data="weight_data"  :weight_labels="weight_labels" class="line-chart-resize" ></line-chart>-->
<!--            </div>-->

<!--          </div>-->
<!--        </router-link>-->


      </Slick>
    </div>
  </div>
</template>
<script>
import Slick from 'vue-slick';
// import LineChart  from "@/components/partials/Charts/LineChart";
import Water03 from "@/components/partials/Water03";
import _ from 'underscore'
export default {
  components: {
    Slick,
    // LineChart,
    Water03,
  },
  props: [ 'data'],
  data () {
    return {
      statistics_swiper_options: {
        slidesToShow: 1,
        dots: true,
      },
    }
  },
  computed: {
    calories_percentage(){
      if (this.data.calory){
        return (this.data.calory.current_cal * 100) / this.data.calory.recommended_total_calory
      } else {
        return 0
      }
    },
    weight_labels() {
      if (this.data){
        let labels = _.keys(this.data.weight_month)
        return labels
      } else {
        return []
      }
    },
    weight_data() {
      if (this.data){
        let data =  _.values(this.data.weight_month)
        return data
      } else  {
        return []
      }
    }
  },
}
</script>