<template>
  <div class="main single" v-if="data">
    <div class="single__cover">
      <img :src="data.image" alt="" class="single__cover__img">
      <div class="abs-full-block gradient-bg-violet">

      </div>
      <div class="single__cover__body">
        <p class="single__cover__info"><img src="../../../assets/images/time.svg" alt=""> Время чтения: {{ data.reading_time }} минут</p>
        <p class="single__cover__info"><img src="../../../assets/images/date.svg" alt=""> Дата: {{ data.created_at | moment }}</p>
        <h1 class="single__cover__title">{{ data.title }}</h1>
      </div>
    </div>
    <div class="single__body">
      <div class="container-ul">
        <div class="grey_line mb-3"></div>

        <p class="single__content" v-html="data.body"> </p>

      </div>
    </div>

  </div>
</template>
<script>
import moment from "moment";
import Blog from "@/api/Blog";

export default {
  data() {
    return {
      data: null,
      errors: null
    }
  },
  mounted() {
    this.getBlog()
  },
  filters: {
    moment(date) {
      return moment(new Date(date)).format('MMMM DD, YYYY') ;
    }
  },
  methods:{
    getBlog(){
      Blog.show(data => this.data = data, errors => this.errors = errors,  this.$route.params.id)
    },
  }
}
</script>