<template>
  <div class="main">
    <div class="container-ul pt-3" v-if="blogs">
      <h1 class="base-title">Топ статья</h1>
      <router-link
          class="block-middle"
          :to="{ name: 'blog', params: {id: blogs.data[0].id} }"
      >
        <div class="block-middle__cover">
          <img :src="blogs.data[0].image" alt="" class="block-middle__img">
          <div class="base-flex">
            <p class="block-middle__info mr-1"><img src="../../../assets/images/date_grey.svg" alt=""> {{ blogs.data[0].created_at | moment }}</p>
            <p class="block-middle__info"><img src="../../../assets/images/time_grey.svg" alt=""> {{ blogs.data[0].reading_time }} минут</p>
          </div>
        </div>
        <div class="block-middle__body">
          <p class="block-middle__title mb-1">{{ blogs.data[0].title }}</p>
          <p class="block-middle__text">{{ blogs.data[0].short_description }}</p>
        </div>
      </router-link>

      <h1 class="base-title mt-2">Все новости</h1>

      <template v-if="blogs.data.length > 1">
        <router-link
            class="block-small"
            v-for="(item, index) in blogs.data.slice(1, blogs.data.length+1)"
            :key="'blog'+index"
            :to="{ name: 'blog', params: {id: item.id} }" >

          <img :src="item.image" alt="" class="block-small__cover border">
          <div class="block-small__body">
            <p class="block-small__title">{{ item.title }}</p>
            <p class="block-small__text">Сайт рыбатекст поможет дизайнеру  более менее осмысленного текста рыбы на русском языке, </p>
            <div class="d-flex">
              <p class="block-small__info mr-2"><img src="../../../assets/images/date_grey.svg" alt=""> {{ item.created_at | moment }}</p>
              <p class="block-small__info"><img src="../../../assets/images/time_grey.svg" alt=""> {{ item.reading_time }} минут</p>
            </div>
          </div>
        </router-link>
      </template>

      <paginate
          :page-count="Math.ceil(blogs.meta.total/blogs.meta.per_page)"
          :click-handler="onPaginate"
          :prev-text="'<'"
          :next-text="'>'"
          :container-class="'pagination'">
      </paginate>

    </div>
  </div>
</template>
<script>
import moment from 'moment'
import Blog from "@/api/Blog";
export default {
  data() {
    return {
      blogs: null,
      errors: null,
      page: 1,
      per_page: 5,
    }
  },
  mounted() {
    this.getBlogs()
  },
  filters: {
    moment(date) {
      return moment(new Date(date)).format('MMMM DD, YYYY') ;
    }
  },
  watch: {
    page () {
      this.getBlogs()
    }
  },
  methods:{
    getPage(){
      // console.log('getpage')
    },
    getBlogs(){
      let params = {
        page: this.page,
        per_page: this.per_page
      }
      // console.log(params)
      Blog.list(data => this.blogs = data, errors => this.errors = errors, params)
    },
    onPaginate (page) {
      this.page = page
    }
  }
}
</script>