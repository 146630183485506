<template>
  <div class="goal-step abs-full-block">


    <div class="sign-in__flex" >
      <div class="w-100">
        <p class="text-muted mb-0">4 из 6</p>
        <p class="goal-step__title">Есть ли ограничения?</p>

        <template v-if="restrictions">
          <label class="base-label">Выберите тип болезни</label>
          <select name="cars" id="cars" class="base-input" v-model="illness">
            <option :value="null" selected style="display: none">Тип болезни</option>
            <option
                v-for="(item, index) in restrictions"
                :key="'restriction'+index"
                :value="{name: item.name, 'id': item.id}"
            >{{ item.name }}</option>
          </select>
          <div>
          <span class="base-tag" v-for="(item, index) in illness_list" :key="index">
            {{ item.name }}
            <img src="../../../assets/images/close_tag.svg" alt="X" class="base-tag__delete" @click="deleteIllness(item)">
          </span>
          </div>
        </template>
        <Preloader v-else></Preloader>


      </div>

      <div class="w-100">
        <RegistrationMenu/>
        <button  @click="collectData" class="base-btn base-btn--violet">Продолжить</button>
      </div>

    </div>
  </div>
</template>
<script>
import RegistrationMenu from '../../partials/RegistrationMenu'
import Restrictions from "@/api/Restrictions";
import _ from 'underscore'
import Preloader from '../../static/Preloader'
export default {
  components: {
    RegistrationMenu,
    Preloader
  },
  data() {
    return {
      illness: null,
      illness_list: [],
      restrictions: null,
      errors: null
    }
  },
  watch: {
    'illness'() {
      if (this.illness){
        this.illness_list.push(this.illness)
      }
    }
  },

  mounted() {
    this.getRestrictions()
  },
  methods: {
    getRestrictions() {
      Restrictions.list(data => this.restrictions = data, errors => this.errors = errors)
    },
    collectData() {
      let restriction_id = _.uniq(
          _.map(this.illness_list, (item)=>{
            return item.id
          })
      )

      let params = {
        disease_ids: restriction_id
      }

      this.$store.dispatch('COLLECT_USER_DATA', params)
          .then(() => {
            this.$router.push({ name: 'registration-step-5' })
          })
    },
    deleteIllness(item){
      let index = this.illness_list.indexOf(item);
      // console.log(index)
      if (index > -1) {
        this.illness_list.splice(index, 1);
      }
    }
  }
}
</script>