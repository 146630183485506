<template>
  <div class="container-ul pt-3 add-recipe">
    <p class="add-recipe__title">Конструктор блюда</p>
    <p class="add-recipe__subtitle ">Загрузить фото</p>
    <div class="upload">
      <div class="text-center upload__before" v-if="!file">
        <img src="../../../assets/images/upload.svg" alt="">
        <p class="add-recipe__subtitle accent">Загрузит изображение</p>
        <input type="file" @change="onFileChange">
      </div>
      <div class="upload__after" v-if="file">
        <img :src="file" alt="" class="upload__after__image">
        <p class="upload__after__delete" @click="deleteFile">X</p>
      </div>
    </div>


    <p class="add-recipe__subtitle mt-3">Характеристика блюда</p>
    <div>
      <input type="text" placeholder="Введите имя блюда" class="form-control mb-2">

      <select name="cars" class="form-control mb-2" v-model="category">
        <option :value="null" selected style="display: none">Выберите категорию </option>
        <option :value="{name: 'Saab', 'id': 1}">Saab</option>
        <option :value="{name: 'Mercedes', 'id': 2}">Mercedes</option>
        <option :value="{name: 'Audi', 'id': 3}">Audi</option>
      </select>

      <input type="number" class="form-control mb-2" placeholder="Введите массу">

      <select name="cars" class="form-control mb-2" v-model="type">
        <option :value="null" selected style="display: none">Выберите тип готовки</option>
        <option :value="{name: 'Saab', 'id': 1}">Saab</option>
        <option :value="{name: 'Mercedes', 'id': 2}">Mercedes</option>
        <option :value="{name: 'Audi', 'id': 3}">Audi</option>
      </select>

    </div>
    <p class="add-recipe__subtitle mt-3">Прием пищи</p>
    <div class="row">
      <div class="col-6">
        <label class="base-checkbox">Завтрак
          <input type="checkbox" checked="checked" value="Веган">
          <span class="checkmark"></span>
        </label>
        <label class="base-checkbox">Обед
          <input type="checkbox" checked="checked" value="Веган">
          <span class="checkmark"></span>
        </label>
        <label class="base-checkbox">Ужин
          <input type="checkbox" checked="checked" value="Веган">
          <span class="checkmark"></span>
        </label>
      </div>
      <div class="col-6">
        <label class="base-checkbox">Перекус
          <input type="checkbox" checked="checked" value="Веган">
          <span class="checkmark"></span>
        </label>
        <label class="base-checkbox">Второй ужин
          <input type="checkbox" checked="checked" value="Веган">
          <span class="checkmark"></span>
        </label>
      </div>
    </div>

    <p class="add-recipe__subtitle mt-3">Описание</p>
    <tinymce id="d1" v-model="description" :plugins="tiny_plugins"></tinymce>

    <p class="add-recipe__subtitle mt-3">Рецепт</p>

    <div class="steps">
      <p class="add-recipe__subtitle accent mb-1 mt-2">Шаг 1</p>
      <textarea class="form-control" rows="3" placeholder="Введите описание"></textarea>

      <p class="add-recipe__subtitle accent mb-1 mt-2">Шаг 2</p>
      <textarea class="form-control" rows="3" placeholder="Введите описание"></textarea>

      <p class="add-recipe__subtitle accent mb-1 mt-2">Шаг 3</p>
      <textarea class="form-control" rows="3" placeholder="Введите описание"></textarea>
    </div>

    <div class="text-right pt-2">
      <button class="add-recipe__subtitle btn btn-light accent" @click="addStep">+ добавить Шаг</button>
    </div>

    <p class="add-recipe__subtitle mt-3">Ингредиенты</p>

    <div class="step_ingredients">
      <div class="mb-3">
        <div class="base-flex">
          <div class="counter_ingredients">1</div>
          <select name="cars" class="form-control mb-2" v-model="type">
            <option :value="null" selected style="display: none">Выберите ингредиент</option>
            <option :value="{name: 'Saab', 'id': 1}">Saab</option>
            <option :value="{name: 'Mercedes', 'id': 2}">Mercedes</option>
            <option :value="{name: 'Audi', 'id': 3}">Audi</option>
          </select>
        </div>
        <div class="row">
          <div class="col-6">
            <select name="cars" class="form-control mb-2" v-model="type">
              <option :value="null" selected style="display: none">Выберите тип</option>
              <option :value="{name: 'Saab', 'id': 1}">Штук</option>
              <option :value="{name: 'Mercedes', 'id': 2}">Вес</option>
            </select>
          </div>
          <div class="col-6">
            <input type="text" class="form-control" placeholder="количество">
          </div>
        </div>
      </div>

      <div class="mb-3">
        <div class="base-flex">
          <div class=" counter_ingredients">2</div>
          <select name="cars" class="form-control mb-2" v-model="type">
            <option :value="null" selected style="display: none">Выберите ингредиент</option>
            <option :value="{name: 'Saab', 'id': 1}">Saab</option>
            <option :value="{name: 'Mercedes', 'id': 2}">Mercedes</option>
            <option :value="{name: 'Audi', 'id': 3}">Audi</option>
          </select>
        </div>
        <div class="row">
          <div class="col-6">
            <select name="cars" class="form-control mb-2" v-model="type">
              <option :value="null" selected style="display: none">Выберите тип</option>
              <option :value="{name: 'Saab', 'id': 1}">Штук</option>
              <option :value="{name: 'Mercedes', 'id': 2}">Вес</option>
            </select>
          </div>
          <div class="col-6">
            <input type="text" class="form-control" placeholder="количество">
          </div>
        </div>
      </div>

    </div>

    <div class="text-right pt-2">
      <button class="add-recipe__subtitle btn btn-light accent" @click="addIngredient">+ добавить ингредиент</button>
    </div>

    <div class="mt-4">
      <button class="base-btn base-btn--gradient">Создать</button>
    </div>


  </div>
</template>
<script>

import tinymce from 'vue-tinymce-editor'
import $ from 'jquery'
export default {
  name: 'app',
  components: {
    tinymce
  },
  data () {
    return {
      file: null,
      category: null,
      type: null,
      description: '',
      tiny_plugins: [],
      add_step: 4,
      add_ingredient_step: 3,
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 250,
        // maxFilesize: 0.5,
        headers: { "My-Awesome-Header": "header value" },
        addRemoveLinks: true,
        maxFiles: 1,
        uploadMultiple: false,
        maxNumberOfFiles: 1,
      }
    }
  },
  methods: {
    deleteFile(){
      this.file = null
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.file = URL.createObjectURL(file);
      // console.log(file)

      // let formData = new FormData();
      // formData.append('file', this.file);
    },
    addStep() {
      $('.steps').append('<p class="add-recipe__subtitle accent mb-1 mt-2">' +
          'Шаг ' + this.add_step++   +
          '</p> <textarea class="form-control" rows="3" placeholder="Введите описание"></textarea>')
    },
    addIngredient() {
      $('.step_ingredients').append('<div class="mb-3">\n' +
          '        <div class="base-flex">\n' +
          '          <div class=" counter_ingredients">'+ this.add_ingredient_step++  + '</div>\n' +
          '          <select name="cars" class="form-control mb-2" v-model="type">\n' +
          '            <option :value="null" selected style="display: none">Выберите ингредиент</option>\n' +
          '            <option :value="{name: \'Saab\', \'id\': 1}">Saab</option>\n' +
          '            <option :value="{name: \'Mercedes\', \'id\': 2}">Mercedes</option>\n' +
          '            <option :value="{name: \'Audi\', \'id\': 3}">Audi</option>\n' +
          '          </select>\n' +
          '        </div>\n' +
          '        <div class="row">\n' +
          '          <div class="col-6">\n' +
          '            <select name="cars" class="form-control mb-2" v-model="type">\n' +
          '              <option :value="null" selected style="display: none">Выберите тип</option>\n' +
          '              <option :value="{name: \'Saab\', \'id\': 1}">Штук</option>\n' +
          '              <option :value="{name: \'Mercedes\', \'id\': 2}">Вес</option>\n' +
          '            </select>\n' +
          '          </div>\n' +
          '          <div class="col-6">\n' +
          '            <input type="text" class="form-control" placeholder="количество">\n' +
          '          </div>\n' +
          '        </div>\n' +
          '      </div>')
    }
  }
}
</script>
<style>
.dropzone{
  border: 2px dotted #581FF9;
  border-radius: 10px;
}

</style>