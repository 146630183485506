<template>
  <div class="goal-step abs-full-block">
    <div class="sign-in__flex">
      <div class="w-100">
        <p class="goal-step__title">Ваша цель?</p>
        <template v-if="purposes">
          <label class="goal-step__selection" v-for="(item, index) in purposes" :key="index" :for="'selection'+item.id">
            <input type="radio" :id="'selection'+item.id" name="goal" :value="item.id" v-model="purpose_id">
            <div class="goal-step__selection__block">
              <div>
                <p class="goal-step__selection__title">{{ item.name }}</p>
                <p class="goal-step__selection__body">{{ item.description }}</p>
              </div>
              <img :src="item.image" :alt="item.name">
            </div>
          </label>
        </template>
        <Preloader v-else></Preloader>



        <div class="w-100">
          <button  @click="updateData" class="base-btn base-btn--violet mt-4">Сохранить</button>
          <router-link :to="{name: 'profile'}" class="forgot-password mb-3">Отменить</router-link>
        </div>



      </div>

    </div>
  </div>
</template>
<script>
import Purposes from "@/api/Purposes";
import Profile from "@/api/Profile";
import Preloader from '../../static/Preloader'
export default {
  components: {
    Preloader
  },
  data(){
    return {
      purposes: null,
      errors: null,
      purpose_id: null,
    }
  },

  mounted() {
    this.getPurposes()
  },
  methods: {
    getPurposes() {
      Purposes.list(data => this.purposes = data, errors => this.errors = errors)
    },
    updateData() {
      if (this.purpose_id !== null  ) {
        let params = {
          purpose_id: this.purpose_id
        }
        Profile.editPurpose(data => this.data = data, errors => this.errors = errors, params)
            .then(() => {
              this.$store.dispatch('SHOW_ALERT', [this.data.message])
              setTimeout(() => {
                this.$router.push({ name: 'profile' })
              }, 0)
            })
      } else {
        this.$store.dispatch('SHOW_ALERT', ['Выберите вашу цель'])
      }
    },
  }
}
</script>