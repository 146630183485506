<template>
  <svg width="26" height="31" viewBox="10 5 26 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d)">
      <path d="M11.5992 16.2614L12.5398 27.015H22.5488L23.4894 16.2614H11.5992Z" :fill="color"/>
      <path d="M25.0886 10H10L11.5799 28.0625H23.5086L25.0886 10ZM21.589 25.9675H13.4996L12.7422 17.3087H22.3464L21.589 25.9675Z" fill="#ECF0F1"/>
      <path d="M25.0886 10H17.5443V17.3088H22.3464L21.589 25.9675H17.5443V28.0624H23.5087L25.0886 10Z" fill="#D2D8D8"/>
    </g>
    <defs>
      <filter id="filter0_d" x="0" y="0" width="26.0886" height="28.0625" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset/>
        <feGaussianBlur stdDeviation="5"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
      </filter>
    </defs>
  </svg>

</template>
<script>
export default {
  props: ['color']
}
</script>