import { Line } from 'vue-chartjs'


export default {
    extends: Line,
    props: ['data_line'],
    mounted () {
        this.renderChart({
            labels: this.data_line.labels,

            datasets: [
                {
                    data: this.data_line.datasets.data,
                    label: this.data_line.datasets.label,
                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                    pointBackgroundColor: 'white',
                    pointBorderColor: '#A71DFC',
                    pointBorderWidth: '2',
                    borderColor: '#A71DFC',
                    spanGaps: false,
                }
            ]
        }, {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false,
            },

            defaultFontColor: 'white',
            scales: {
                yAxes: [{
                    stacked: false,
                    ticks: {
                        // max: 150,
                        // min: 0,
                        stepSize: 10,
                        fontColor: "#9C9C9C",
                        display: false
                    },
                    gridLines: {
                        display: false
                    }
                }],
                xAxes: [{
                    stacked: true,
                    gridLines: {
                        // display: false,
                    },
                    ticks: {
                        fontColor: "#9C9C9C",
                    },
                }],
            },
            layout: {
                padding: {
                    // left: -50,
                    right: 0,
                    top: 10,
                    bottom: 0
                }
            }
        })
    }
}
