<template>
  <div class="block-large">
    <transition name="animate-ul" enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut" >
      <div class="block-large__abs" v-if="show">
        <div class="block-large__abs__body">
          <button class="block-large__abs__close" @click="toggleInfo"><img src="../../assets/images/close_tag.svg" alt="" ></button>

          <table class="w-100 border-0">
            <tr
                v-for="(item, index) in data.lights"
                :key="index+'light_info'"
            >
              <td>
              <span class="mr-1">
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="5" cy="5" r="5" :fill="item.colorHex"/>
                </svg>
              </span>
                {{item.name}}
              </td>
              <td>{{item.weight}} {{item.unit}}</td>
            </tr>
          </table>
        </div>
      </div>
    </transition>
    <div class="block-large__top">
      <!--      <pre>{{data.lights}}</pre>-->
      <img :src="data.image" alt="" class="block-large__img">
      <!--      <button-->
      <!--          class="block-large__top__btn"-->
      <!--          @click="replaceDish"-->
      <!--      ><img src="../../assets/images/refresh.svg" > Заменить</button>-->
    </div>
    <div class="block-large__body">

      <div class="block-large__body__top">
        <div @click="toggleInfo">
          <span
              class="mr-1"
              v-for="(item, index) in data.lights"
              :key="'light'+index"
          >
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="5" cy="5" r="5" :fill="item.colorHex"/>
            </svg>
          </span>
        </div>
      </div>
      <p class="block-large__title">  {{ data.name }}</p>
      <div class="d-flex">
        <p class="mr-3 block-large__info"><img src="../../assets/images/food.svg" alt=""> {{ data.kilocalories }} кал</p>
        <p class="block-large__info" v-if="data.weight"><img src="../../assets/images/weight.svg" alt=""> {{ data.weight }} грамм</p>
      </div>
      <div class="base-flex">
        <router-link
            class="block-large__link"
            :to="{name: 'recipe', params: {id: data.id}}"
        >Посмотреть рецепт</router-link>
        <!--        <button class="delete-btn"><img src="../../assets/images/trash.svg" alt=""> Удалить</button>-->
      </div>
    </div>
  </div>

</template>
<script>
export default {
  props: ['data'],
  data() {
    return {
      show: false
    }
  },
  methods: {
    toggleInfo() {
      this.show = !this.show
    }
  }
}
</script>