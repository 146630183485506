<template>
  <button v-if="preloader === id" class="plan__menu__body__edit plan__menu__body__preloader" ><img src="../../assets/images/loading.svg" alt="Загрузка..."></button>
</template>
<script>
export default {
  props: ['id'],
  computed: {
    preloader(){
      return this.$store.getters.getLoader()
    }
  }
}
</script>