import {HTTP} from '@/http'

class Recipe {

    static list(then, catcher, params = null) {
        return HTTP.get('/recipes', {params})
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }
    static show(then, catcher, id,  params = null) {
        return HTTP.get('/dishes/'+ id, params)
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }

    static getComments(then, catcher, id,  params = null) {
        return HTTP.get('/dish-comments/'+ id, params)
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }

    static sendComment(then, catcher, params = null) {
        return HTTP.post('/dish-comments', params)
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }

    static dishTypes(then, catcher, params = null) {
        return HTTP.get('/dish-types', {params})
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }

    static micronutrients(then, catcher, params = null) {
        return HTTP.get('/micronutrients', {params})
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }

    static dishCategories(then, catcher, params = null) {
        return HTTP.get('/dish-categories', {params})
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }

}
export default Recipe