<template>
  <div class="goal-step abs-full-block">
    <div class="sign-in__flex">
      <div class="w-100">
        <p class="goal-step__title">Хочу меню без...</p>
      </div>
      <div class="w-100">
        <label class="base-checkbox"
               v-for="(item, index) in limitations"
               :key="'limitations'+index"
               :for="'limitation'+item.id"
        >
          {{ item.name }}
          <input type="checkbox" :id="'limitation'+item.id"  :value="item.id" v-model="limitation_list">
          <span class="checkmark"></span>
        </label>
      </div>

      <div class="w-100">
        <button  @click="updateData" class="base-btn base-btn--violet">Сохранить</button>
        <router-link :to="{name: 'profile'}" class="forgot-password mb-3">Отменить</router-link>
      </div>

    </div>
  </div>
</template>
<script>
import Preferences from "@/api/Preferences"
import Profile from "@/api/Profile";
export default {
  data() {
    return {
      limitation_list: [],
      limitations: null,
      errors: null
    }
  },

  mounted() {
    this.getPreferences()
  },
  methods: {
    getPreferences(){
      Preferences.list(data => this.limitations = data, errors => this.errors = errors)
    },
    updateData() {
      let params = {
        limitation_ids: this.limitation_list
      }
      Profile.editPreferences(data => this.data = data, errors => this.errors = errors, params)
          .then(() => {
            this.$store.dispatch('SHOW_ALERT', [this.data.message])
            setTimeout(() => {
              this.$router.push({ name: 'profile' })
            }, 0)
          })
    },
  }
}
</script>