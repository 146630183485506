<template>
  <div class="gradient-swiper">
    <Slick
        ref="slick"
        :options="swiper_options" >
      <router-link class="gradient-swiper__block"
                   v-for="(blog, index) in blogs"
                   :key="index"
                   :to="{name: 'blog', params: {id: blog.id}}"
      >
        <div class="gradient-swiper__block__out">
          <div :class="['abs-full-block', {'gradient-bg-violet': index % 2 === 0, 'gradient-bg-green': index % 2 !== 0 }]">
            <p class="gradient-swiper__title"> {{blog.title}} </p>
            <div class="base-flex">
              <p><img src="../../../assets/images/date.svg" alt=""> {{blog.created_at}} </p>
              <p><img src="../../../assets/images/time.svg" alt=""> {{blog.reading_time}} минут</p>
            </div>
          </div>
          <img class="gradient-swiper__img" :src="blog.image" alt="">
        </div>
      </router-link>

    </Slick>
  </div>
</template>
<script>
import Slick from 'vue-slick';
export default {
  components: {
    Slick,
  },
  props: ['blogs'],
  data() {
    return {
      swiper_options: {
        slidesToShow: 2,
        arrows: false,
        dots: false
      },
    }
  },
}
</script>