<template>
  <div class="goal-step abs-full-block">

    <div class="sign-in__flex">
      <div class="w-100">
        <p class="text-muted mb-0">1 из 6</p>
        <p class="goal-step__title">Регистрация </p>

        <label class="base-label">Имя </label>
        <input class="base-input" type="text"  placeholder=" " v-model="user.name">

        <label class="base-label">Фамилия</label>
        <input class="base-input" type="text"  placeholder=" " v-model="user.surname">

        <label class="base-label">Пол</label>
        <select class="base-input" v-model="user.gender">
          <option :value="null" selected style="display: none">Выберите пол</option>
          <option value="1">Мужской</option>
          <option value="2">Женский</option>
        </select>

        <label class="base-label">Вес (в кг)</label>
        <input class="base-input" type="number"  placeholder=" " v-model="user.weight">

        <label class="base-label">Рост</label>
        <input class="base-input" type="number"  placeholder=" " v-model="user.height">

        <label class="base-label">Дата рождения</label>
        <VueDatePicker 
          class="base-input" 
          format="YYYY-MM-DD" 
          :max-date="new Date().toISOString().substr(0, 10)"
          min-date="1940-01-01"
          v-model="user.birthdate"
          />
      
        <RegistrationMenu/>
        <div class="w-100">
            <button  @click="collectData" class="base-btn base-btn--violet">Продолжить</button>
          </div>
      </div>

    </div>
  </div>
</template>
<script>
import RegistrationMenu from '../../partials/RegistrationMenu'
import { VueDatePicker } from '@mathieustan/vue-datepicker';
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';
export default {
 data() {
    return {
      user: {
        name: null,
        surname: null,
        gender: null,
        weight: null,
        height:null,
        birthdate:null
      },
      alert: false
    }
  },
  components: {
   RegistrationMenu,
   VueDatePicker
  },
  mounted() {
    this.checkAuth()
  },
  methods: {
    checkAuth() {
      if(localStorage.token){
        this.$router.push({ name: 'main' })
      }
    },
    collectData() {
      if (this.user.name !== null && this.user.name.length > 2 &&
          this.user.surname !== null && this.user.surname.length > 2 &&
          this.user.gender !== null &&
          this.user.height !== null && this.user.height.length > 1 &&
          this.user.birthdate !== null ) {
        this.$store.dispatch('COLLECT_USER_DATA', this.user)
        .then(() => {
          this.$router.push({ name: 'registration-step-2' })
        })
      } else {
        this.$store.dispatch('SHOW_ALERT', ['Заполните все поля'])
      }
    },
  }
}
</script>