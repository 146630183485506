<template>
  <div class="main calendar">
    <div class="container-ul">
      <div class="main__top">
        <button class="main__top__arrow" @click="previousDay"><img src="./../../assets/images/left.svg" alt=""></button>
        <p class="main__top__title">{{ date }}</p>
        <button class="main__top__arrow" @click="nextDay"><img src="./../../assets/images/right.svg" alt=""></button>
      </div>
      <div class="calendar-select">
          <date-picker :date="startTime" :option="option" ></date-picker>
        <img src="../../assets/images/calendar.svg" alt="" class="calendar-select__icon">
      </div>
      <div class="week-ul">
        <div class="week-ul__day" v-if="week_days[0]">
          <p class="week-ul__name">По</p>
          <p :class="classObject(week_days[0])" @click="getDayInfo(week_days[0])">{{ week_days[0].getDate() }}</p>
        </div>
        <div class="week-ul__day" v-if="week_days[1]">
          <p class="week-ul__name">Вт</p>
          <p :class="classObject(week_days[1])" @click="getDayInfo(week_days[1])">{{ week_days[1].getDate()  }}</p>
        </div>
        <div class="week-ul__day" v-if="week_days[2]">
          <p class="week-ul__name">Ср</p>
          <p :class="classObject(week_days[2])" @click="getDayInfo(week_days[2])">{{ week_days[2].getDate()  }}</p>
        </div>
        <div class="week-ul__day" v-if="week_days[3]">
          <p class="week-ul__name">Чт</p>
          <p :class="classObject(week_days[3])" @click="getDayInfo(week_days[3])">{{ week_days[3].getDate()  }}</p>
        </div>
        <div class="week-ul__day" v-if="week_days[4]">
          <p class="week-ul__name">Пт</p>
          <p :class="classObject(week_days[4])" @click="getDayInfo(week_days[4])">{{ week_days[4].getDate()   }}</p>
        </div>
        <div class="week-ul__day" v-if="week_days[5]">
          <p class="week-ul__name">Сб</p>
          <p :class="classObject(week_days[5])" @click="getDayInfo(week_days[5])">{{ week_days[5].getDate()   }}</p>
        </div>
        <div class="week-ul__day" v-if="week_days[6]">
          <p class="week-ul__name">Вс</p>
          <p :class="classObject(week_days[6])" @click="getDayInfo(week_days[6])">{{ week_days[6].getDate()   }}</p>
        </div>

      </div>

      <div class="calendar__list" v-if="data">

        <div class="block-small"
             v-for="(item,index) in data.breakfast_dishes"
             :key="index+'breakfast'"
        >
          <img :src="item.photo" alt="" class="block-small__cover">
          <div class="block-small__body">

            <div class="base-flex">
              <p class="block-small__accent" ><span class="green-mark"></span> Завтрак</p>
              <button
                  class="replace-btn"
                  @click="replaceDish(item.id)"
              ><img src="../../assets/images/refresh.svg" > Заменить</button>
            </div>

            <p class="block-small__title-big">{{ item.name }}</p>
            <div class="d-flex">
              <p class="block-small__info mr-2 mb-1"><img src="../../assets/images/meal-grey.svg" alt=""> {{ item.kilocalories }} кал</p>
              <p class="block-small__info mb-1"><img src="../../assets/images/weight-meal.svg" alt=""> {{ item.weight }}  грамм</p>
            </div>
            <router-link
                class="block-small__accent text-underline"
                :to="{name: 'recipe', params: {id: item.id}}"
            >Посмотреть рецепт</router-link>
          </div>
        </div>
        <div class="block-small"
             v-for="(item,index) in data.lunch_dishes"
             :key="index+'lunch'"
        >
          <img :src="item.photo" alt="" class="block-small__cover">
          <div class="block-small__body">

            <div class="base-flex">
              <p class="block-small__accent" ><span class="green-mark"></span> Обед</p>
              <button
                  class="replace-btn"
                  @click="replaceDish(item.id)"
              ><img src="../../assets/images/refresh.svg" > Заменить</button>
            </div>

            <p class="block-small__title-big">{{ item.name }}</p>
            <div class="d-flex">
              <p class="block-small__info mr-2 mb-1"><img src="../../assets/images/meal-grey.svg" alt=""> {{ item.kilocalories }} кал</p>
              <p class="block-small__info mb-1"><img src="../../assets/images/weight-meal.svg" alt=""> {{ item.weight }}  грамм</p>
            </div>
            <router-link
                class="block-small__accent text-underline"
                :to="{name: 'recipe', params: {id: item.id}}"
            >Посмотреть рецепт</router-link>
          </div>
        </div>

        <div class="block-small"
             v-for="(item,index) in data.dinner_dishes"
             :key="index+'dinner'"
        >
          <img :src="item.photo" alt="" class="block-small__cover">
          <div class="block-small__body">
            <div class="base-flex">
              <p class="block-small__accent" ><span class="green-mark"></span> Ужин</p>
              <button
                  class="replace-btn"
                  @click="replaceDish(item.id)"
              ><img src="../../assets/images/refresh.svg" > Заменить</button>
            </div>

            <p class="block-small__title-big">{{ item.name }}</p>
            <div class="d-flex">
              <p class="block-small__info mr-2 mb-1"><img src="../../assets/images/meal-grey.svg" alt=""> {{ item.kilocalories }} кал</p>
              <p class="block-small__info mb-1"><img src="../../assets/images/weight-meal.svg" alt=""> {{ item.weight }}  грамм</p>
            </div>
            <router-link
                class="block-small__accent text-underline"
                :to="{name: 'recipe', params: {id: item.id}}"
            >Посмотреть рецепт</router-link>

          </div>
        </div>
      </div>
      <Preloader v-else></Preloader>
    </div>
  </div>
</template>
<script>
import DatePicker from 'vue-datepicker'
import DailyRation from "@/api/DailyRation";
import Preloader from '../static/Preloader'
import moment from 'moment'
export default {
  components: {
    DatePicker,
    Preloader
  },
  data () {
    return {

      date: null,
      date_for_select: null,
      week_days: [],

      calendar_date: '',
      startTime: {
        time: ''
      },
      option: {
        type: 'day',
        week: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
        month: ['Январь ', 'Февраль ', 'Март ', 'Апрель ', 'Май ', 'Июнь ', 'Июль ', 'Август ', 'Сентябрь ', 'Октябрь ', 'Ноябрь ', 'Декабрь'],
        format: 'YYYY.MM.DD',
        placeholder: 'Выбрать дату из календаря',
        inputStyle: {
          'display': 'block',
          'padding': '4px 15px',
          'font-size': '12px',
          'border': '1px solid #561BFB',
          'border-radius': '10px',
          'color': '#000000',
          'width': '200px',
        },
        color: {
          header: '#561BFB',
          headerText: '#FFFFFF'
        },
        overlayOpacity: 0.5,
        buttons: {
          ok: 'Выбрать',
          cancel: 'Отменить'
        },
      },
      data: null,
      errors: null,
    }
  },
  mounted() {
    this.currentDay()
    this.getWeekDays()
    this.getDayPlan()
  },
  computed: {
    dish_changed() {
      return this.$store.getters.dish_status()
    }
  },
  watch: {
    'date'(){
      this.classObject(this.date_for_select)
      this.getDayPlan()
    },
    'startTime.time'(){
       this.formatDateForCalendar(this.startTime.time)
    },
    'dish_changed'() {
      this.getDayPlan()
    }
  },
  methods: {
    replaceDish(id) {
      this.$store.dispatch('DISH_CHANGED', id)
    },
    getDayPlan() {
      let date_to_params = moment(this.date_for_select).format('DD-MM-YYYY')
      let params = {
        date: date_to_params
      }
      DailyRation.generateDayPlan(data => this.data = data, errors => this.errors = errors, params )
    },
    getDayInfo(date){
      this.formatDate(date)
    },
    classObject (date) {
      let date1 = this.date_for_select.setHours(0, 0, 0, 0)
      let date2 = date.setHours(0, 0, 0, 0)
      return {
        'active-week-day': date1.toString() === date2.toString(),
        'week-ul__date': true
      }
    },
    currentDay () {
      let currentDate = new Date();
      this.formatDate(currentDate)
    },
    nextDay() {
      let current_day = new Date(this.date_for_select);
      let nextDay = new Date(current_day);
      nextDay.setDate(current_day.getDate() + 1);
      this.formatDate(nextDay)
    },
    previousDay() {
      let current_day = new Date(this.date_for_select);
      let previousDay = new Date(current_day);
      previousDay.setDate(current_day.getDate() - 1);
      this.formatDate(previousDay)
    },
    getWeekDays() {
      function days(current) {
        let week = [];
        // Starting Monday not Sunday
        let first = ((current.getDate() - current.getDay()) + 1);
        for (let i = 0; i < 7; i++) {
          // let date_to_format = new Date(current.setDate(first++))
          week.push(
              // date_to_format.toLocaleString('ru-RU', { day: '2-digit', month: '2-digit', year: 'numeric' })
              new Date(current.setDate(first++))
          );
        }
        return week;
      }
      let input = new Date();
      this.week_days = days(input);


    },
    formatDate(date){
      let formatted = date.toLocaleString('ru-RU', { day: '2-digit', month: 'long', year: 'numeric' })
      this.date = formatted
      this.date_for_select = date
    },
    formatDateForCalendar(date){
      let date_from_calendar = new Date(date)

      let formatted = date_from_calendar.toLocaleString('ru-RU', { day: '2-digit', month: 'long', year: 'numeric' })
      this.date = formatted

      this.date_for_select = date_from_calendar
    },

  }
}
</script>