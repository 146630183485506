import {HTTP} from '@/http'

class DailyRation {

    static toggleStatus(then, catcher, params = null) {
        return HTTP.post('/day-plan/toggle-status', params)
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }

    static generateDayPlan(then, catcher, params = null) {
        return HTTP.get('/day-plan', {params})
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }

    static generateWeekPlan(then, catcher, params = null) {
        return HTTP.get('/weekly-plan', {params})
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }


}
export default DailyRation
