import {HTTP} from "@/http";
import Auth from "@/api/Auth";
import _ from 'underscore'

const state = {
    user: {},
    auth: null
}

const getters = {
    getUser: state => () => state.user,
    isAuthenticated: state => () => !!state.user
}

const mutations = {
    LOG_IN(state, user) {
        state.auth = user
    },
    LOG_OUT(state) {
        state.auth = {}
    },
    collect_user_data(state, user_info) {
        let cloned = _.extend(state.user, user_info)
        state.user = cloned
    }
}

const actions = {
    COLLECT_USER_DATA: ({commit}, user_info) => {
        commit('collect_user_data', user_info)
    },
    LOG_IN: ({ commit }, user) => {
        commit('LOG_IN', user)
    },
    SET_USER: ({commit}, data = null) => {
        if (data) {
            localStorage.token = data.token
            HTTP.defaults.headers.common['Authorization'] = 'Bearer ' + data.token
            // location.reload()
            commit('LOG_IN', data)

        } else {
            localStorage.removeItem('token')
            delete HTTP.defaults.headers.common['Authorization']
            commit('LOG_OUT')
        }
    },
    SET_USER_FROM_TOKEN: ({commit}) => {
        let user = {}
        Auth.me(data => user = data, () => localStorage.removeItem('token'))
        setTimeout(() => {
            commit('LOG_IN', user)
        }, 1000)
    }
}


export default {
    state,
    getters,
    actions,
    mutations
}
