import {HTTP} from '@/http'

class Progress {

    static getMolecularAtlas(then, catcher, params = null) {
        return HTTP.get('/molecular-atlas-of-health', {params})
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }

}
export default Progress
