<template>
  <div id="app">
    <div class="app">
      <transition
          name="animate-ul"
          enter-active-class="animate__animated animate__slideInDown"
          leave-active-class="animate__animated animate__slideOutUp">
        <div class="alert-ul" v-if="alert">
          <div class="alert-ul__close" @click="hideAlert">
            <img src="./assets/images/close_alert.svg" alt="">
          </div>
          <div>
            <p class="mb-0" v-for="(item, index) in alert_context" :key="index+'alert'">{{ item }}</p>
          </div>
        </div>
      </transition>


      <transition name="animate-ul" enter-active-class="animate__animated animate__fadeIn" >
        <router-view></router-view>
      </transition>
      <Menu v-if="menu"/>
    </div>
<!--    Modal for change meal -->
    <transition name="animate-ul" enter-active-class="animate__animated animate__fadeIn" >
      <div class="modal-ul" v-if="modal">
        <div class="modal-ul__top">
          <p class="modal-ul__title">Выберите блюдо</p>
          <span class="modal-ul__close" @click="closeModal"><img src="./assets/images/close_tag.svg" alt=""></span>
        </div>
        <div class="base-tab mt-4">
          <button @click="getRecommended" :class="[ recommended ? 'active' : '', 'base-tab__item']">Рекомендации</button>
          <button @click="getFavourites" :class="[ favourite ? 'active' : '','base-tab__item']">Избранные</button>
        </div>
        <div class="modal-ul__search mt-3" v-if="recommended">
          <form class="input-group mb-4" @submit.prevent="searchRecipe">
            <input
                type="text"
                class="profile__search__input form-control"
                placeholder="Искать по названию"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                v-model="to_search"
            >
            <div class="input-group-append">
              <button class="profile__search__btn" type="submit"><img src="./assets/images/search.svg" alt="Поиск"></button>
            </div>
          </form>
        </div>
        <div class="modal-ul__body">

          <BlockLargeForReplace  v-for="(item, index) in modal_data" :key="index+'replace'" :item="item"></BlockLargeForReplace>

<!--          <pre>{{modal_data[1]}}</pre>-->


        </div>

      </div>
    </transition>

  </div>
</template>

<script>
import Menu from './components/static/Menu'
import moment from "moment";
import BlockLargeForReplace from "@/components/partials/BlockLargeForReplace";
export default {
  name: 'App',
  components: {
    Menu, BlockLargeForReplace
  },
  data() {
    return{
      show: false,
      to_search: null,
      recommended: true,
      favourite: false,
    }
  },
  computed: {
    alert() {
      return this.$store.getters.getAlert()
    },
    modal() {
      return this.$store.getters.getModal()
    },
    modal_data() {
      return this.$store.getters.getModalData()
    },
    alert_context() {
      return this.$store.getters.getAlertContext()
    },
    menu () {
      if (this.$route.matched[0].props.default.menu === true){
        return true
      } else {
        return false
      }
    },

  },
  filters: {
    moment(date) {
      return moment(date, 'DD.MM.YYYY').format('MMMM DD, YYYY') ;
    }
  },
  watch: {
    'alert'(){
      if(this.alert === true) {
        setTimeout(() => {
          this.$store.dispatch('HIDE_ALERT')
        }, 4000)
      }
    },
    $route(){
      if (this.$route.name === 'main') {
        return true
      } else {
        setTimeout(() => {
          this.$store.dispatch('CLOSE_MODAL')
          this.favourite = false
          this.recommended = true
        }, 1000)
      }
    }
  },
  methods: {

    getRecommended(){
      this.recommended = true
      this.favourite = false
      this.$store.dispatch('GET_RECOMMENDED_DATA')
    },
    getFavourites(){
      this.recommended = false
      this.favourite = true
      this.$store.dispatch('GET_FAVOURITE_DATA')
    },
    searchRecipe(){
      let params= {
        name: this.to_search
      }
      this.$store.dispatch('SEARCH_FOR_MODAL_DATA', params)
    },
    closeModal() {
      this.$store.dispatch('CLOSE_MODAL')
      this.favourite = false
      this.recommended = true
    },
    hideAlert() {
      this.$store.dispatch('HIDE_ALERT')
    },
    toggleInfo() {
      this.show = !this.show
    }
  }
}
</script>

<style>

</style>
