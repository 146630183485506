


const state = {
    loader: false,
}

const getters = {
    getLoader: state => () => state.loader,
}

const mutations = {
    SHOW_LOADER(state, payload) {
        state.loader = payload
    },
    HIDE_LOADER(state) {
        state.loader = false
    }
}

const actions = {
    HIDE_LOADER: ({commit}) => {
        commit('HIDE_LOADER')
    },
    SHOW_LOADER: ({commit}, payload) => {
        commit('SHOW_LOADER', payload)
    }
}


export default {
    state,
    getters,
    actions,
    mutations
}
