<template>
  <div class="block-large">
    <transition name="animate-ul" enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut" >
      <div class="block-large__abs" v-if="show">
        <div class="block-large__abs__body">
          <button class="block-large__abs__close" @click="toggleInfo"><img src="../../assets/images/close_tag.svg" alt="" ></button>

          <table class="w-100 border-0">
            <tr
                v-for="(color, indexColor) in item.lights"
                :key="indexColor+'light_info'"
            >
              <td>
                          <span class="mr-1">
                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <circle cx="5" cy="5" r="5" :fill="color.colorHex"/>
                            </svg>
                          </span>
                {{color.name}}
              </td>
              <td>{{color.weight}}</td>
            </tr>
          </table>
        </div>
      </div>
    </transition>
    <div class="block-large__top">
      <img :src="item.image" :alt="item.name" class="block-large__img">
    </div>
    <div class="block-large__body">
      <div class="block-large__body__top">
        <div @click="toggleInfo">
                    <span
                        class="mr-1"
                        v-for="(item_light, index_light) in item.lights"
                        :key="'light'+index_light"
                    >
                      <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="5" cy="5" r="5" :fill="item_light.colorHex"/>
                      </svg>
                    </span>
        </div>
      </div>
      <p class="block-large__title mb-1">  {{ item.name }}  </p>
      <div class="d-flex align-items-end justify-content-between pb-2">
        <p class="base-text mb-0"> <span v-if="item.description">{{item.description.length > 60 ? item.description.slice(0, 60)+'...' : null}}</span></p>
        <button class="base-btn-small base-btn-small--violet ml-3" @click="dishChosen">Выбрать</button>
      </div>
    </div>
  </div>

</template>
<script>
export default {
  props: ['item'],
  data() {
    return {
      show: false
    }
  },
  methods: {
    toggleInfo() {
      this.show = !this.show
    },
    dishChosen() {
      let params = {
        dish_id: this.item.id,
      }
      this.$store.dispatch('DISH_CHOSEN', params)
    }
  }
}
</script>