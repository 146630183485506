<template>
  <div class="main calendar plan">
    <div class="container-ul">
<!--      <div class="main__top">-->
<!--        <button class="main__top__arrow" @click="previousDay"><img src="./../../../assets/images/left.svg" alt=""></button>-->
<!--        <p class="main__top__title">{{ date }}</p>-->
<!--        <button class="main__top__arrow" @click="nextDay"><img src="./../../../assets/images/right.svg" alt=""></button>-->
<!--      </div>-->
<!--      <div class="calendar-select">-->
<!--        <date-picker :date="startTime" :option="option" ></date-picker>-->
<!--        <img src="../../../assets/images/calendar.svg" alt="" class="calendar-select__icon">-->
<!--      </div>-->
<!--      <div class="week-ul">-->
<!--        <div class="week-ul__day" v-if="week_days[0]">-->
<!--          <p class="week-ul__name">По</p>-->
<!--          <p :class="classObject(week_days[0])" @click="getDayInfo(week_days[0])">{{ week_days[0].getDate() }}</p>-->
<!--        </div>-->
<!--        <div class="week-ul__day" v-if="week_days[1]">-->
<!--          <p class="week-ul__name">Вт</p>-->
<!--          <p :class="classObject(week_days[1])" @click="getDayInfo(week_days[1])">{{ week_days[1].getDate()  }}</p>-->
<!--        </div>-->
<!--        <div class="week-ul__day" v-if="week_days[2]">-->
<!--          <p class="week-ul__name">Ср</p>-->
<!--          <p :class="classObject(week_days[2])" @click="getDayInfo(week_days[2])">{{ week_days[2].getDate()  }}</p>-->
<!--        </div>-->
<!--        <div class="week-ul__day" v-if="week_days[3]">-->
<!--          <p class="week-ul__name">Чт</p>-->
<!--          <p :class="classObject(week_days[3])" @click="getDayInfo(week_days[3])">{{ week_days[3].getDate()  }}</p>-->
<!--        </div>-->
<!--        <div class="week-ul__day" v-if="week_days[4]">-->
<!--          <p class="week-ul__name">Пт</p>-->
<!--          <p :class="classObject(week_days[4])" @click="getDayInfo(week_days[4])">{{ week_days[4].getDate()   }}</p>-->
<!--        </div>-->
<!--        <div class="week-ul__day" v-if="week_days[5]">-->
<!--          <p class="week-ul__name">Сб</p>-->
<!--          <p :class="classObject(week_days[5])" @click="getDayInfo(week_days[5])">{{ week_days[5].getDate()   }}</p>-->
<!--        </div>-->
<!--        <div class="week-ul__day" v-if="week_days[6]">-->
<!--          <p class="week-ul__name">Вс</p>-->
<!--          <p :class="classObject(week_days[6])" @click="getDayInfo(week_days[6])">{{ week_days[6].getDate()   }}</p>-->
<!--        </div>-->

<!--      </div>-->

      <div class="calendar__list">
<!--        <div class="notification">-->
<!--          <img src="../../../assets/images/alert_pink.svg" alt="" class="mr-2">-->
<!--          <p class="mb-0">Хватит кушать пожалуйста!  У вас превышена норма потребления калорий</p>-->
<!--        </div>-->

        <div class="plan__goal base-flex" v-if="user_info">
          <div class="w-100 mr-2">
            <p class="plan__goal__title">{{ user_info.purpose }}</p>
            <div class="d-flex w-100">
              <p class="plan__goal__info mr-3">Вес: {{ user_info.weight }} </p>
              <p class="plan__goal__info">Возраст: {{ user_info.age }} </p>
            </div>
            <router-link
                class="plan__goal__btn"
                :to="{name: 'edit-goal'}"
            ><img src="../../../assets/images/pencil.svg" alt="" class="profile__block__edit">Изменить план</router-link>
          </div>
          <img src="../../../assets/images/plan-for-day.svg" alt="">
        </div>
        <Preloader v-else></Preloader>

        <p class="base-title-bold">Задания на сегодня</p>
 

        <div class="statistics__block gradient-slider-1 statistics__flex my-3" v-if="statistics">

          <div>
            <p class="statistics__type">Калорий</p>
            <p class="statistics__text">суточная норма на день</p>
            <div class="statistics__flex">
              <div>
                <p class="statistics__number"> <span class="text-green">{{ statistics.statistics.calory.left_cal }}</span> ккал</p>
                <p class="statistics__text">осталось</p>
              </div>
              <div>
                <p class="statistics__number"> <span class="text-pink">{{ statistics.statistics.calory.recommended_total_calory }}</span> ккал</p>
                <p class="statistics__text">рекомендовано</p>
              </div>
            </div>
          </div>

          <div>
            <div class="progress-ul">
              <svg>
                <circle  cx="60" cy="60" r="60"></circle>
                <circle  cx="60" cy="60" r="60" :style="{'stroke-dashoffset': 380 - (380 * calories_percentage) / 100 }"></circle>
              </svg>
              <div class="progress-ul__info">
                <p class="progress-ul__number">{{ statistics.statistics.calory.current_cal }}</p>
                <p class="progress-ul__name">калорий</p>
                <div class="progress-ul__abs"></div>
              </div>
            </div>
          </div>
        </div>
        <Preloader v-else></Preloader>
        
        <div class="statistics__block gradient-slider-2" v-if="statistics">
          <p class="statistics__type">Вода</p>
          <div class="statistics__flex">
            <div>
              <span v-for="(n, index) in statistics.statistics.water.current_water_glasses" :key="index+'drunk'">
                <img src="../../../assets/images/water-drunk.svg" alt="">
              </span>
              <span v-for="(n, index) in ( statistics.statistics.water.recommended_water_glasses - statistics.statistics.water.current_water_glasses)" :key="index+'plus'">
                <img src="../../../assets/images/plus-water.svg" alt="" @click="addWater">
              </span>
            </div>
          </div>
        </div>
        <div class="plan__progress" v-if="statistics">

          <div>
            <p class="plan__progress__title">Белки</p>
            <div class="plan__progress__flex">
              <div class="plan__progress__block">
                <div class="plan__progress__abs gradient-pink-to-violet" :style="{'width': protein_progress + '%'}"></div>
              </div>
              <p class="plan__progress__amount">{{ statistics.statistics.pfc.protein.current }}<span>/{{ statistics.statistics.pfc.protein.total }}</span></p>
            </div>
          </div>

          <div>
            <p class="plan__progress__title">Углеводы</p>
            <div class="plan__progress__flex">
              <div class="plan__progress__block">
                <div class="plan__progress__abs gradient-blue-to-green" :style="{'width': carbs_progress + '%'}"></div>
              </div>
              <p class="plan__progress__amount">{{ statistics.statistics.pfc.carbs.current }}<span>/{{ statistics.statistics.pfc.carbs.total }}</span></p>
            </div>
          </div>

          <div>
            <p class="plan__progress__title">Жиры</p>
            <div class="plan__progress__flex">
              <div class="plan__progress__block">
                <div class="plan__progress__abs gradient-blue-to-violet" :style="{'width': fat_progress + '%'}"></div>
              </div>
              <p class="plan__progress__amount">{{ statistics.statistics.pfc.fat.current }}<span>/{{ statistics.statistics.pfc.fat.total }}</span></p>
            </div>
          </div>

        </div>
        <div class="plan__menu pt-3">
          <div class="base-flex">
            <p class="base-title-bold">Питание</p>
            <router-link :to="{name: 'atlas' }" custom v-slot="{ navigate }">
              <button @click="navigate" @keypress.enter="navigate" class="plan__menu__btn" role="link">Молекулярный атлас здоровья</button>
            </router-link>
          </div>
          
          <template v-if="dishes">
            <div class="plan__menu__block"
                 v-for="(item,index) in dishes.breakfast_dishes"
                 :key="index+'breakfast'"
            >
              <div class="plan__menu__head">
                <img :src="item.photo" :alt="item.name" class="w-100 rounded plan__menu__image">
                <p class="plan__menu__head__title">Завтрак</p>
              </div>
              <div class="plan__menu__body">
                <p class="plan__menu__body__amount"><img src="../../../assets/images/fire.svg" alt=""> <span class="text-bold">
                {{ item.kilocalories }}</span> ккал</p>
                <p class="plan__menu__body__title">{{ item.name }}</p>
                <div class="base-flex">
                  <p class="plan__menu__body__weight"><img src="../../../assets/images/weight-meal.svg" alt=""> {{ item.weight }} грамм</p>

                  <div @click="toggleStatus(item.plan_id)">
                    <PreloaderSmall :id="item.plan_id"></PreloaderSmall>
                  </div>
                  <template>
                    <button v-if="item.status === 0" class="plan__menu__body__edit" @click="toggleStatus(item.plan_id)"><img src="../../../assets/images/plus.svg" alt=""></button>
                    <button v-if="item.status === 1 " class="plan__menu__body__edit" @click="toggleStatus(item.plan_id)"><img src="../../../assets/images/minur-for-toggle.svg" alt=""></button>
                  </template>

                </div>
              </div>
            </div>

            <div class="plan__menu__block"
                 v-for="(item,index) in dishes.snack_one_dishes"
                 :key="index+'perekus1'"
            >
              <div class="plan__menu__head">
                <img :src="item.photo" :alt="item.name" class="w-100 rounded plan__menu__image">
                <p class="plan__menu__head__title">Перекус</p>
              </div>
              <div class="plan__menu__body">
                <p class="plan__menu__body__amount"><img src="../../../assets/images/fire.svg" alt=""> <span class="text-bold">
                {{ item.kilocalories }}</span> ккал</p>
                <p class="plan__menu__body__title">{{ item.name }}</p>
                <div class="base-flex">
                  <p class="plan__menu__body__weight"><img src="../../../assets/images/weight-meal.svg" alt=""> {{ item.weight }} грамм</p>

                  <div @click="toggleStatus(item.plan_id)">
                    <PreloaderSmall :id="item.plan_id"></PreloaderSmall>
                  </div>
                  <template>
                    <button v-if="item.status === 0" class="plan__menu__body__edit" @click="toggleStatus(item.plan_id)"><img src="../../../assets/images/plus.svg" alt=""></button>
                    <button v-if="item.status === 1 " class="plan__menu__body__edit" @click="toggleStatus(item.plan_id)"><img src="../../../assets/images/minur-for-toggle.svg" alt=""></button>
                  </template>

                </div>
              </div>
            </div>

            <div class="plan__menu__block"
                 v-for="(item,index) in dishes.lunch_dishes"
                 :key="index+'lunch'"
            >
              <div class="plan__menu__head">
                <img :src="item.photo" :alt="item.name"  class="w-100 rounded plan__menu__image">
                <p class="plan__menu__head__title">Обед</p>
              </div>
              <div class="plan__menu__body">
                <p class="plan__menu__body__amount"><img src="../../../assets/images/fire.svg" alt=""> <span class="text-bold">{{ item.kilocalories }}</span> ккал</p>
                <p class="plan__menu__body__title">{{ item.name }}</p>
                <div class="base-flex">
                  <p class="plan__menu__body__weight"><img src="../../../assets/images/weight-meal.svg" alt=""> {{ item.weight }} грамм</p>

                  <div @click="toggleStatus(item.plan_id)">
                    <PreloaderSmall :id="item.plan_id"></PreloaderSmall>
                  </div>
                  <template>
                    <button v-if="item.status === 0" class="plan__menu__body__edit" @click="toggleStatus(item.plan_id)"><img src="../../../assets/images/plus.svg" alt=""></button>
                    <button v-if="item.status === 1 " class="plan__menu__body__edit" @click="toggleStatus(item.plan_id)"><img src="../../../assets/images/minur-for-toggle.svg" alt=""></button>
                  </template>

                </div>
              </div>
            </div>


            <div class="plan__menu__block"
                 v-for="(item,index) in dishes.snack_two_dishes"
                 :key="index+'perekus2'"
            >
              <div class="plan__menu__head">
                <img :src="item.photo" :alt="item.name" class="w-100 rounded plan__menu__image">
                <p class="plan__menu__head__title">Перекус</p>
              </div>
              <div class="plan__menu__body">
                <p class="plan__menu__body__amount"><img src="../../../assets/images/fire.svg" alt=""> <span class="text-bold">
                {{ item.kilocalories }}</span> ккал</p>
                <p class="plan__menu__body__title">{{ item.name }}</p>
                <div class="base-flex">
                  <p class="plan__menu__body__weight"><img src="../../../assets/images/weight-meal.svg" alt=""> {{ item.weight }} грамм</p>

                  <div @click="toggleStatus(item.plan_id)">
                    <PreloaderSmall :id="item.plan_id"></PreloaderSmall>
                  </div>
                  <template>
                    <button v-if="item.status === 0" class="plan__menu__body__edit" @click="toggleStatus(item.plan_id)"><img src="../../../assets/images/plus.svg" alt=""></button>
                    <button v-if="item.status === 1 " class="plan__menu__body__edit" @click="toggleStatus(item.plan_id)"><img src="../../../assets/images/minur-for-toggle.svg" alt=""></button>
                  </template>

                </div>
              </div>
            </div>


            <div class="plan__menu__block"
                 v-for="(item,index) in dishes.dinner_dishes"
                 :key="index+'dinner'"
            >
              <div class="plan__menu__head">
                <img  :src="item.photo" :alt="item.name"  class="w-100 rounded plan__menu__image">
                <p class="plan__menu__head__title">Ужин</p>
              </div>
              <div class="plan__menu__body">
                <p class="plan__menu__body__amount"><img src="../../../assets/images/fire.svg" alt=""> <span class="text-bold">{{ item.kilocalories }}</span> ккал</p>
                <p class="plan__menu__body__title">{{ item.name }}</p>
                <div class="base-flex">
                  <p class="plan__menu__body__weight"><img src="../../../assets/images/weight-meal.svg" alt=""> {{ item.weight }} грамм</p>

                  <div @click="toggleStatus(item.plan_id)">
                    <PreloaderSmall :id="item.plan_id"></PreloaderSmall>
                  </div>
                  <template>
                    <button v-if="item.status === 0" class="plan__menu__body__edit" @click="toggleStatus(item.plan_id)"><img src="../../../assets/images/plus.svg" alt=""></button>
                    <button v-if="item.status === 1 " class="plan__menu__body__edit" @click="toggleStatus(item.plan_id)"><img src="../../../assets/images/minur-for-toggle.svg" alt=""></button>
                  </template>
                </div>
              </div>
            </div>
          </template>
          <Preloader v-else></Preloader>
        </div>
      </div>


      <template v-if="dishes">
        <template v-if="dishes.snack_three_dishes.lenght">
          <div class="plan__menu__block"
                  v-for="(item,index) in dishes.snack_three_dishes"
                  :key="index+'perekus3'"
              >
              <div class="plan__menu__head">
                <img :src="item.photo" :alt="item.name" class="w-100 rounded plan__menu__image">
                <p class="plan__menu__head__title">Перекус</p>
              </div>
              <div class="plan__menu__body">
                <p class="plan__menu__body__amount"><img src="../../../assets/images/fire.svg" alt=""> <span class="text-bold">
                {{ item.kilocalories }}</span> ккал</p>
                <p class="plan__menu__body__title">{{ item.name }}</p>
                <div class="base-flex">
                  <p class="plan__menu__body__weight"><img src="../../../assets/images/weight-meal.svg" alt=""> {{ item.weight }} грамм</p>

                  <div @click="toggleStatus(item.plan_id)">
                    <PreloaderSmall :id="item.plan_id"></PreloaderSmall>
                  </div>
                  <template>
                    <button v-if="item.status === 0" class="plan__menu__body__edit" @click="toggleStatus(item.plan_id)"><img src="../../../assets/images/plus.svg" alt=""></button>
                    <button v-if="item.status === 1 " class="plan__menu__body__edit" @click="toggleStatus(item.plan_id)"><img src="../../../assets/images/minur-for-toggle.svg" alt=""></button>
                  </template>

                </div>
              </div>
            </div>
        </template>
      </template>
<!-- 
      <div class="base-flex-title">
        <h1 class="base-title">Планы диет</h1>
        <router-link :to="{name: 'diet-plans'}" class="base-link">Все диеты</router-link>
      </div>
      <template v-if="diets">
        <PlanSwiper :data="diets.data"/>
      </template>
      <Preloader v-else></Preloader> -->
 
    </div>
  </div>
</template>
<script>
// import PlanSwiper from "../../partials/Swiper/PlanSwiper";
import DailyRation from "@/api/DailyRation";
import Diet from "@/api/Diet";
import Profile from "@/api/Profile";
import Statistics from "@/api/Statistics";
import Preloader from '../../static/Preloader'
import PreloaderSmall from "@/components/static/PreloaderSmall";
export default {
  components: {
    Preloader, PreloaderSmall
  },
  data () {
    return {
      user_info: null,
      statistics: null,
      dishes: null,
      errors: null,
      components_progress: {
        protein: 60,
        carbohydrate: 75,
        fats: 48
      },
      progress: {
        amount: 1920,
        percentage: 65
      },
      diets: null,
      toggle_status: null,
      preloader_for_toggle: false,
      water_data: null
    }
  },
  computed: {
    calories_percentage(){
      if (this.statistics.statistics.calory){
        return (this.statistics.statistics.calory.current_cal * 100) / this.statistics.statistics.calory.recommended_total_calory
      } else {
        return 0
      }
    },
    protein_progress() {
      if (this.statistics) {
        return (this.statistics.statistics.pfc.protein.current * 100 ) / this.statistics.statistics.pfc.protein.total
      } else {
        return 0
      }
    },
    carbs_progress() {
      if (this.statistics) {
        return (this.statistics.statistics.pfc.carbs.current * 100 ) / this.statistics.statistics.pfc.carbs.total
      } else {
        return 0
      }
    },
    fat_progress() {
      if (this.statistics) {
        return (this.statistics.statistics.pfc.fat.current * 100 ) / this.statistics.statistics.pfc.fat.total
      } else {
        return 0
      }
    }
  },
  mounted() {
    this.getDayPlan()
    this.getDiets()
    this.getProfile()
    this.getCurrentDayStatistics()
  },
  methods: {
    toggleStatus(plan_id){
      this.$store.dispatch('SHOW_LOADER', plan_id)
      let params = {
        plan_id: plan_id
      }
      DailyRation.toggleStatus(data => this.toggle_status = data, errors => this.errors = errors, params)
      .then(() => {
        this.getDayPlan()
        this.getCurrentDayStatistics()
        setTimeout(() => {
          this.$store.dispatch('HIDE_LOADER')
        }, 1500)
      })
    },
    getCurrentDayStatistics() {
      Statistics.getCurrentDayData(data => this.statistics = data, errors => this.errors = errors )
    },
    getProfile() {
      Profile.me(data => this.user_info = data, errors => this.errors = errors)
    },
    getDayPlan() {
      DailyRation.generateDayPlan(data => this.dishes = data, errors => this.errors = errors )
    },
    getDiets(){
      Diet.list(data => this.diets = data, errors => this.errors = errors)
    },
    addWater(){
      Statistics.addWater(data => this.water_data = data, errors => this.errors = errors)
      .then(()=> {
        this.getCurrentDayStatistics()
      })
    },
    addCalorie(item) {
      console.log(item)
    }


  }
}
</script>