import {HTTP} from '@/http'

class Blog {

    static list(then, catcher, params = null) {
        return HTTP.get('/articles', {params})
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }
    static show(then, catcher, id,  params = null) {
        return HTTP.get('/articles/'+ id, params)
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }

}
export default Blog
