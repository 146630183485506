import {HTTP} from '@/http'

class ReplaceDish {

    static replaceDish(then, catcher, params = null) {
        return HTTP.post('/replace-dish', params)
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }
    static getRecommendedForReplace(then, catcher, params = null) {
        return HTTP.get('/get-recommended-replace', {params})
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }
    static getFavouriteForReplace(then, catcher, params = null) {
        return HTTP.get('/get-favorites-replace', {params})
            .then(({data}) => then(data))
            .catch((errors) => catcher(errors.response.data))
    }

}
export default ReplaceDish
