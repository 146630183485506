<template>
  <div class="container-ul pt-3 profile">
    <p class="profile__title">Моя семья</p>

    <form class="input-group mb-4" @submit.prevent="searchRelatives">
      <input
          type="text"
          class="profile__search__input form-control"
          placeholder="Искать родных по имени"
          aria-label="Recipient's username"
          aria-describedby="basic-addon2"
          v-model="to_search"
      >
      <div class="input-group-append">
        <button class="profile__search__btn" type="submit"><img src="../../../assets/images/search.svg" alt="Поиск"></button>
      </div>
    </form>

    <template v-if="search_result">
      <div
          class="base-flex my-2 profile__relatives" >
        <div class="base-flex-start">
          <img v-if="search_result.avatar" :src="search_result.avatar" class="profile__relatives__img">
          <img v-else src="../../../assets/images/default-user-image.png" alt="" class="profile__relatives__img">
          <p class="mb-0 profile__relatives__name">{{ search_result.name}} {{ search_result.surname}}</p>
        </div>
        <button
            class="btn btn-light accent"
            @click="sendRequestToAdd(search_result.id)"
        >Добавить</button>
      </div>
    </template>

    <template v-if="request_to_me">
      <p class="profile__subtitle mt-3">Запросы на добавление</p>

      <div
          class="base-flex my-2 profile__relatives"
          v-for="(item, index) in request_to_me"
          :key="index+'requests_to_me'"

      >
        <div class="base-flex-start">
          <img v-if="item.image" :src="item.image" class="profile__relatives__img">
          <img v-else src="../../../assets/images/default-user-image.png" alt="" class="profile__relatives__img">
          <p class="mb-0 profile__relatives__name">{{ item.name}} {{ item.surname}}</p>
        </div>
        <div>
          <button class="profile__relatives__btn" @click="approve(item.sibling_id, item.from_user_id)"><img src="../../../assets/images/relative_add.svg" alt=""></button>
          <button class="profile__relatives__btn"><img src="../../../assets/images/relative_delete.svg" alt=""></button>
        </div>
      </div>
    </template>

    <template v-if="family_list">
      <p class="profile__subtitle mt-3">Список родных</p>

      <div
          class="base-flex my-2 profile__relatives"
          v-for="(item, index) in family_list"
          :key="'family'+index"
      >
        <div class="base-flex-start">
          <img v-if="item.image" :src="item.image" class="profile__relatives__img">
          <img v-else src="../../../assets/images/default-user-image.png" alt="" class="profile__relatives__img">
          <p class="mb-0 profile__relatives__name">{{ item.name}} {{ item.surname}}</p>
        </div>
        <div>
          <button class="profile__relatives__btn" ><img src="../../../assets/images/relative_delete.svg" alt=""></button>
        </div>
      </div>
    </template>
    <pre v-if="search_result">
      {{search_result}}
    </pre>
    <pre v-if="data">
      {{data}}
    </pre>
  </div>
</template>
<script>
import Profile from "@/api/Profile";
export default {
  data() {
    return {
      to_search: null,
      search_result: null,
      family_list: null,
      request_to_me: null,
      errors: null,
      data: null
    }
  },
  mounted() {
    this.getFamilyList()
    this.getRequestsToMe()
  },
  methods: {
    approve(sibling_id, from_user_id){
      let params = {
        sibling_id: sibling_id,
        from_user_id: from_user_id
      }
      Profile.approveRequestToMe(data => this.data = data, errors => this.errors = errors, params)
    },
    getRequestsToMe() {
      Profile.requestsToMe(data => this.request_to_me = data, errors => this.errors = errors)
    },
    sendRequestToAdd(id) {
      console.log(id)
      if (id) {
        let params = {
          sibling_user_id: id
        }
        Profile.sendRequestToAddFamilyMember(data => this.data = data, errors => this.errors = errors, params)
      }
    },
    getFamilyList() {
      Profile.family(data => this.family_list = data, errors => this.errors = errors)
    },
    searchRelatives(){
      if (this.to_search !== null && this.to_search.length > 3 ) {
        let params = {
          nickname: this.to_search
        }
        Profile.searchFamilyMember(data => this.search_result = data, errors => this.errors = errors, params)
      } else {
        this.$store.dispatch('SHOW_ALERT', ['Заполняите форму поиска'])
      }
    }
  }
}
</script>